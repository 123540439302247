import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaBan } from "react-icons/fa";

// Map our internal size specification ("small", "medium", or "large")
// to the syntax accepted by the FontAwesomeIcon component (for use
// when passing a FontAwesome icon name by string, our old usage).

const faSizes = {
  small: "sm",
  medium: "lg",
  large: "2x",
};

// Map our bulma-style size specification to the syntax accepted by a
// react-icons icon component (our new usage).
const sizes = {
  small: "0.866em",
  medium: "1.33em",
  large: "2em",
  larger: "2.5em",
};

// Map our bulma-style size specification to a size one or two larger, for
// puttng an icon around another.

const sizeLarger = (size) =>
  size === "small"
    ? "medium"
    : size === undefined
    ? "medium"
    : size === "medium"
    ? "large"
    : size === "large"
    ? "large"
    : "medium";

// Main function. If omitted or undefined, outerSize will be taken to
// be the same as size. If null, it results in no "is-..." size attribute
// at all being sent to the outer span.

function Icon(props) {
  const {
    icon,
    size,
    outerSize = size,
    color,
    negated,
    negationColor,
    className,
  } = props;
  const Component = typeof icon === "string" ? FontAwesomeIcon : icon;

  return (
    <span
      className={
        "icon react-icons" +
        (outerSize ? " is-" + outerSize : "") +
        (color ? " has-text-" + color : "") +
        (negated ? " negated" : "") +
        (className ? " " + className : "")
      }
    >
      {typeof icon === "string" ? (
        <FontAwesomeIcon
          icon={icon}
          {...(size && faSizes[size] ? { size: faSizes[size] } : {})}
        />
      ) : (
        <Component {...(size && sizes[size] ? { size: sizes[size] } : {})} />
      )}
      {negated ? (
        <span
          className={
            "icon react-icons" +
            (outerSize ? " is-" + outerSize : "") +
            (negationColor ? " has-text-" + negationColor : "")
          }
        >
          <FaBan size={sizes[sizeLarger(size)]} />
        </span>
      ) : null}
    </span>
  );
}

export default Icon;
