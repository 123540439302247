import { createSlice } from "@reduxjs/toolkit";

export const browseMonthSlice = createSlice({
  name: "browseMonth",
  initialState: { uninitialized: true },
  reducers: {
    set: (state, action) => {
      const { year, month } = action.payload;
      delete state.uninitialized;
      state.year = year;
      state.month = month;
    },
  },
});

export const selectBrowseMonth = (state) => state.browseMonth;
export default browseMonthSlice.reducer;
