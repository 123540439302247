import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiPayloadCreator } from "util/api";

const apiCall = createAsyncThunk(
  "search/apiCall",
  apiPayloadCreator(["metadata", "videos/search"], "get")
);

export const searchSlice = createSlice({
  name: "search",
  initialState: {
    searching: false,
    failed: null,
    lastSearch: "",
    results: {},
  },
  reducers: {},

  extraReducers: {
    [apiCall.pending]: (state, action) => {
      state.searching = true;
      state.failed = null;
    },
    [apiCall.fulfilled]: (state, action) => {
      const {
        arg: { query },
      } = action.meta;
      state.searching = false;
      state.failed = null;
      state.results[query] = action.payload;
      state.lastSearch = query;
    },
    [apiCall.rejected]: (state, action) => {
      const {
        arg: { query },
      } = action.meta;
      state.searching = false;
      state.failed = { query, failure: action.payload };
    },
  },
});

searchSlice.selector = (store) => store.search;

searchSlice.asyncActions = {
  search: (query) => (dispatch) => {
    dispatch(
      apiCall({
        query: query,
        path: "",
        params: { query },
      })
    );
  },
};

export default searchSlice.reducer;
