import React from "react";
import { Switch, Route } from "react-router-dom";
import ViewingPage from "pages/viewing";
import SearchPage from "pages/search";
import BrowsePage from "pages/browse";
import ListPage from "pages/list";
import HomePage from "pages/home";
import LegacyPage from "pages/legacy";

function CommonApp(props) {
  const { authenticated } = props;

  return (
    <Switch>
      <Route path="/view/:id">
        <ViewingPage authenticated={authenticated} />
      </Route>
      <Route path="/search">
        <SearchPage />
      </Route>
      <Route path="/browse/:year/:month">
        <BrowsePage />
      </Route>
      <Route path="/browse">
        <BrowsePage />
      </Route>
      <Route path="/list/event/:id">
        <ListPage what="event" />
      </Route>
      <Route path="/list/speaker/:id">
        <ListPage what="speaker" />
      </Route>
      <Route path="/event/:eventcode/:year?">
        <LegacyPage what="event" />
      </Route>
      <Route path="/:year([0-9]+)/:month/:talkcode">
        <LegacyPage what="talk" />
      </Route>
      <Route>
        <HomePage />
      </Route>
    </Switch>
  );
}

export default CommonApp;
