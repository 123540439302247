import { createSlice } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";

export const resolutionSlice = createSlice({
  name: "resolution",
  initialState: "auto",
  reducers: {
    set: (state, action) => {
      return action.payload;
    },
  },
});

resolutionSlice.selector = (state) => state.resolution;

export function useResolutionSelection() {
  const dispatch = useDispatch();
  const resolution = useSelector(resolutionSlice.selector);
  return [
    resolution,
    (newResolution) => dispatch(resolutionSlice.actions.set(newResolution)),
  ];
}

export default resolutionSlice.reducer;
