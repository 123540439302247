import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { Provider } from "react-redux";
import React from "react";

import { enableMapSet } from "immer";

import authReducer from "redux/features/auth";
import routesReducer from "redux/features/routes";
import metadataReducer from "redux/features/metadata";
import streamPropertiesReducer from "redux/features/streamProperties";
import searchTermReducer from "redux/features/searchTerm";
import resolutionReducer from "redux/features/resolutions";
import browseMonthReducer from "redux/features/browseMonth";
import searchReducer from "redux/features/search";
import browseReducer from "redux/features/browse";
import legacyReducer from "redux/features/legacy";
import reportViewingReducer from "redux/features/reportViewing";

import thunk from "redux-thunk";

enableMapSet();

const rootReducer = combineReducers({
  auth: authReducer,
  routes: routesReducer,
  metadata: metadataReducer,
  streamProperties: streamPropertiesReducer,
  searchTerm: searchTermReducer,
  resolution: resolutionReducer,
  browseMonth: browseMonthReducer,
  search: searchReducer,
  browse: browseReducer,
  legacy: legacyReducer,
  reportViewing: reportViewingReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

function OurProvider(props) {
  return <Provider store={store}>{props.children}</Provider>;
}

export default OurProvider;
