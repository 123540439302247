import React, { useState, useEffect, useCallback, Fragment } from "react";

function FadingPanel(props) {
  const {
    step = 0.05,
    interval = 100,
    prefadeTime = 2000,
    initialPreFadeTime = 4000,
    children,
    panelComponent,
    started,
    ...divProps
  } = props;

  const [fadeState, setFadeState] = useState({
    active: true,
    startFadingAt: null,
    awaitingStart: true,
    fading: false,
    opacity: 1.0,
  });
  const { awaitingStart } = fadeState;
  useEffect(() => {
    if (started && awaitingStart) {
      setFadeState((s) => {
        return {
          ...s,
          awaitingStart: false,
          startFadingAt: Date.now() + initialPreFadeTime,
        };
      });
    }
  }, [started, awaitingStart, initialPreFadeTime]);
  const PanelComponent = panelComponent || Fragment;

  const adjustStateAfterTimeout = useCallback(
    (state) => {
      if (state.startFadingAt !== null) {
        if (state.startFadingAt > Date.now())
          // Timer started early; change state object (to trigger a new
          // component render and hence a new timer) but not its contents
          return { ...state };
        else return { ...state, startFadingAt: null, fading: true };
      }

      return !state.fading
        ? state
        : state.opacity > step
        ? { ...state, opacity: state.opacity - step, active: false }
        : { ...state, opacity: 0, fading: false, active: false };
    },
    [step]
  );

  useEffect(() => {
    const updateAfter = fadeState.startFadingAt
      ? Math.max(0, fadeState.startFadingAt - Date.now())
      : fadeState.fading
      ? interval
      : null;

    if (updateAfter === null) return null;
    const id = window.setTimeout(
      () => setFadeState(adjustStateAfterTimeout),
      updateAfter
    );
    return () => window.clearTimeout(id);
  }, [fadeState, step, interval, adjustStateAfterTimeout]);

  const show = useCallback(
    (s) => {
      const wasTransient = s.opacity < 1 || s.fading || s.startFadingAt;
      s.fading = false;
      s.startFadingAt = wasTransient ? Date.now() + prefadeTime : null;
      s.opacity = 1;
    },
    [prefadeTime]
  );

  const hold = useCallback((s) => {
    s.startFadingAt = null;
  }, []);

  const release = useCallback(
    (s) => {
      s.startFadingAt = Date.now() + prefadeTime;
    },
    [prefadeTime]
  );

  const activate = useCallback((s) => {
    s.active = true;
  }, []);

  const activateIfFaded = useCallback((s) => {
    if (s.opacity < 1) s.active = true;
  }, []);

  const activateIfShown = useCallback((s) => {
    if (s.opacity === 1) s.active = true;
  }, []);

  const apply = useCallback(
    (...actions) => (e) => {
      // Debug statements logging e can be added here if needed
      setFadeState((prevState) => {
        const newState = { ...prevState };
        newState.awaitingStart = false;
        actions.forEach((action) => action(newState));
        return newState;
      });
    },
    []
  );

  return (
    <div
      {...divProps}
      onMouseOver={apply(activateIfFaded, show, hold)}
      onTouchStart={apply(activateIfShown, show, release)}
      onTouchMove={apply(show, release)}
      onTouchEnd={apply(show, release)}
      onTouchCancel={apply(show, release)}
      onMouseOut={apply(show, release)}
      onClick={apply(show, activate)}
      style={{ opacity: fadeState.opacity }}
    >
      <PanelComponent active={fadeState.active}>{children}</PanelComponent>
    </div>
  );
}

export default FadingPanel;
