import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiPayloadCreator } from "util/api";

const apiCall = createAsyncThunk(
  "legacy/apiCall",
  apiPayloadCreator(["metadata", "videos/legacy"], "get")
);

export const legacySlice = createSlice({
  name: "legacy",
  initialState: {},
  reducers: {},

  extraReducers: {
    [apiCall.pending]: (state, action) => {
      const {
        arg: { path },
      } = action.meta;
      state[path] = {
        loading: true,
      };
    },
    [apiCall.fulfilled]: (state, action) => {
      const {
        arg: { path },
      } = action.meta;
      state[path] = {
        loading: false,
        result: action.payload,
      };
    },
    [apiCall.rejected]: (state, action) => {
      const {
        arg: { path },
      } = action.meta;
      state[path] = {
        loading: false,
        failed: action.payload,
      };
    },
  },
});

legacySlice.selector = (store) => store.legacy;

legacySlice.asyncActions = {
  load: (path) => (dispatch) => {
    dispatch(
      apiCall({
        path,
      })
    );
  },
};

export default legacySlice.reducer;
