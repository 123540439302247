import React, {
  createContext,
  useContext,
  useRef,
  useEffect,
  useLayoutEffect,
} from "react";

import MathJax from "mathjax3-react";

import { useMathJaxContext } from "mathjax3-react/dist/providers/Provider";

const typesetStatusContext = createContext();

export const MathJaxProvider = ({ children }) => {
  const typesetStatusRef = useRef({ layoutGeneration: 0, promises: {} });
  const typesetStatus = typesetStatusRef.current;
  return (
    <typesetStatusContext.Provider value={typesetStatus}>
      <MathJax.Provider
        options={{
          ignoreHtmlClass: "tex2jax_ignore",
          processHtmlClass: "tex2jax_ignore",

          tex: {
            inlineMath: [
              ["$", "$"],
              ["\\(", "\\)"],
            ],
          },
        }}
      >
        <div className="tex2jax_ignore"> {children}</div>
      </MathJax.Provider>
    </typesetStatusContext.Provider>
  );
};

export function Math({ inline, children, className, ...props }) {
  const typesetStatus = useContext(typesetStatusContext);
  const MathJax = useMathJaxContext();

  useLayoutEffect(() => {
    typesetStatus.newLayout = true;
  });
  useEffect(() => {
    if (!typesetStatus.newLayout) return;

    typesetStatus.newLayout = false;
    const previousPromise =
      typesetStatus.promises[typesetStatus.layoutGeneration] ||
      Promise.resolve();
    const newGeneration = 1 + typesetStatus.layoutGeneration;
    typesetStatus.layoutGeneration = newGeneration;
    typesetStatus.promises[newGeneration] = previousPromise.then(() => {
      if (!MathJax || typeof MathJax.typesetPromise !== "function") return null;
      return MathJax.typesetPromise()
        .catch((e) => {
          if (!(e instanceof TypeError)) console.log(e);
        })
        .then(() => {
          delete typesetStatus.promises[newGeneration];
        });
    });
    return;
  });
  const useClass = "tex2jax_process" + (className ? " " + className : "");
  return inline ? (
    <span className={useClass} {...props}>
      {children}
    </span>
  ) : (
    <div className={useClass} {...props}>
      {children}
    </div>
  );
}

export default Math;
