/*
Technical metadata about video resolution, duration, zoom capability, etc.
(as distinct from "metadata" which holds metadata for the person viewing,
such as lecture title, speaker, etc.).
*/

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiPayloadCreator } from "util/api";

// Properties of FieldsLive streams are read from an info.json file on the
// FieldsLive media server, via an api call, triggered by the
// "loadFieldsLiveProperties" asynchronous action.

// Properties of non-FieldsLive simple video files are read by a hidden
// video component that reads the width, height, and duration, constructs
// a pseudo-FieldsLive information object from that, and calls the "set"
// synchronous action to register it

const apiCall = createAsyncThunk(
  "streamProperties/apiCall",
  apiPayloadCreator(["media", "/"], "get")
);

export const streamPropertiesSlice = createSlice({
  name: "streamProperties",
  initialState: {},
  reducers: {
    set: (state, action) => {
      const { id, data } = action.payload;
      state[id] = { loading: false, data };
    },
  },
  extraReducers: {
    [apiCall.pending]: (state, action) => {
      const {
        arg: { id, videoPath },
      } = action.meta;
      if (!state[id]) state[id] = {};
      state[id].loading = true;
      state[id].videoPath = videoPath;
      state[id].failed = null;
    },
    [apiCall.fulfilled]: (state, action) => {
      const {
        arg: { id, videoPath },
      } = action.meta;
      state[id].loading = false;
      state[id].videoPath = videoPath;
      state[id].data = action.payload;
    },
    [apiCall.rejected]: (state, action) => {
      const {
        arg: { id, videoPath },
      } = action.meta;
      state[id].loading = false;
      state[id].videoPath = videoPath;
      state[id].failed = action.payload;
    },
  },
});

streamPropertiesSlice.selector = (store) => store.streamProperties;

streamPropertiesSlice.asyncActions = {
  loadFieldsLiveProperties: (id, videoPath, isEmulated) => (
    dispatch,
    getState
  ) => {
    const current = streamPropertiesSlice.selector(getState())[id];
    const args = { id, videoPath };
    args[isEmulated ? "url" : "path"] = videoPath + "/info.json";
    if (!current || !current.loading) dispatch(apiCall(args));
  },
};

export default streamPropertiesSlice.reducer;
