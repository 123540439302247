import React, { Fragment, useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Math from "util/mathjax";
import IconButton from "util/iconButton";
import { FaVideo } from "react-icons/fa";
import {
  Field,
  Control,
  Checkbox,
} from "react-bulma-components/lib/components/form";
import Columns from "react-bulma-components/lib/components/columns";
import dayjs from "dayjs";
import { searchTermSlice } from "redux/features/searchTerm";
import Dropdown from "util/dropdown";
import unidecode from "unidecode";
import WithTooltip from "util/tooltip";

function DisplayTalk({
  id,
  date,
  time,
  event_title,
  event_id,
  speaker_text,
  speaker_text_override,
  speakers,
  title,
  has_video,
  flags,
  widths,
}) {
  const datetime = dayjs(date + "T" + time + ":00");
  const dayFormat = flags.singleYear
    ? flags.singleEvent
      ? "dddd MMMM D"
      : "ddd MMM D"
    : flags.singleEvent
    ? "dddd MMM D, YYYY"
    : "ddd MMM D, YYYY";
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <Fragment>
      <Columns.Column
        size={widths.dateTime}
        className="talk-list-item  date-time"
      >
        <Columns>
          <Columns.Column> {datetime.format(dayFormat)}</Columns.Column>
          <Columns.Column narrow>{datetime.format("h:mm a")}</Columns.Column>
        </Columns>
      </Columns.Column>

      {flags.singleEvent ? null : (
        <Columns.Column size={widths.event} className="talk-list-item event">
          <Link to={"/list/event/" + event_id}>
            <WithTooltip
              component={Math}
              tip="List all talks/videos in this event"
              tipPosition="top"
            >
              {event_title}
            </WithTooltip>
          </Link>
        </Columns.Column>
      )}

      <Columns.Column size={widths.speaker} className="talk-list-item speaker">
        {speaker_text_override ? (
          <WithTooltip
            component={Link}
            to="/search"
            onClick={(e) => dispatch(searchTermSlice.actions.set(speaker_text))}
            tip={`
This talk is not attached to a specific
speaker profile, but you can search for other
talks/videos with matching speaker text
            `}
            tipPosition="top"
            tipMultiline="centered"
            className="speaker-text"
          >
            {speaker_text}
          </WithTooltip>
        ) : (
          speakers.map((speaker, index) => (
            <Fragment key={index}>
              {index > 0 ? ", " : ""}
              <WithTooltip
                component={Link}
                to={"/list/speaker/" + speaker.id}
                tip={"See all talks/videos by this speaker"}
              >
                {speaker.label}
              </WithTooltip>
            </Fragment>
          ))
        )}
      </Columns.Column>

      <Columns.Column size={widths.title} className="talk-list-item talk-title">
        <Columns gapless>
          <Columns.Column>
            <Math>{title}</Math>
          </Columns.Column>
          <Columns.Column narrow>
            {has_video ? (
              <div className="talk-list-watch">
                <IconButton
                  icon={FaVideo}
                  rounded
                  onClick={(e) => history.push("/view/" + id)}
                >
                  Watch
                </IconButton>
              </div>
            ) : (
              <div className="talk-list-no-video">No video available</div>
            )}
          </Columns.Column>
        </Columns>
      </Columns.Column>
    </Fragment>
  );
}

export function TalkList({
  talks,
  defaultNewestFirst,
  singleEvent,
  singleYear,
}) {
  const [showAll, setShowAll] = useState(false);
  const count = talks.length;
  const videos = talks.filter((talk) => talk.has_video);
  const countWithVideo = videos.length;

  const widths = {};
  [widths.dateTime, widths.event, widths.speaker, widths.title] = singleEvent
    ? [3, 0, 4, 5]
    : [2, 3, 3, 4];

  const flags = { singleEvent, singleYear };

  const [sortBy, setSortBy] = useState("datetime");
  const [newestFirst, setNewestFirst] = useState(false);
  const [reverseAlphabetic, setReverseAlphabetic] = useState(false);

  const datetimeSortFunc = (a, b) => {
    const dtA = a.date + " " + a.time;
    const dtB = b.date + " " + b.time;
    return (newestFirst ? -1 : 1) * (dtA > dtB ? 1 : dtA < dtB ? -1 : 0);
  };

  useEffect(() => {
    if (singleEvent && sortBy === "event_title") setSortBy("datetime");
  }, [singleEvent, sortBy]);
  const sortFunc =
    sortBy === "datetime"
      ? datetimeSortFunc
      : (a, b) => {
          const textA = unidecode(a[sortBy] ?? "").toUpperCase();
          const textB = unidecode(b[sortBy] ?? "").toUpperCase();
          return (
            (reverseAlphabetic ? -1 : 1) *
              (textA > textB ? 1 : textA < textB ? -1 : 0) ||
            datetimeSortFunc(a, b)
          );
        };
  const items = [...(showAll ? talks : videos)].sort(sortFunc);

  const SortableHeader = ({ forWhich, label, intro, dateIntro }) => {
    if (sortBy !== forWhich)
      return (
        <WithTooltip
          component="div"
          className="header-label not-sorted-by"
          tip={intro}
          onClick={(e) => setSortBy(forWhich)}
        >
          {label}
        </WithTooltip>
      );
    const isDate = sortBy === "datetime";
    const [selected, setSelected] = isDate
      ? [newestFirst, setNewestFirst]
      : [reverseAlphabetic, setReverseAlphabetic];

    const [altSelected, setAltSelected] =
      sortBy === "datetime"
        ? [undefined, undefined]
        : [newestFirst, setNewestFirst];

    const options = [{ label: intro + "..." }];
    if (isDate) {
      options.push({ label: "oldest first", value: false });
      options.push({ label: "newest first", value: true });
    } else {
      options.push({ label: "alphabetically (A-Z)", value: false });
      options.push({ label: "reverse alphabetically (Z-A)", value: true });
      options.push({ divider: true });
      options.push({ label: dateIntro });
      options.push({ label: "show oldest first", value: false, isAlt: true });
      options.push({ label: "show newest first", value: true, isAlt: true });
    }

    return (
      <Dropdown
        hoverable
        {...{
          selected,
          setSelected,
          altSelected,
          setAltSelected,
          items: options,
        }}
      >
        <div className="header-label sorted-by">{label}</div>
      </Dropdown>
    );
  };

  return (
    <Fragment>
      {items.length === 0 ? (
        <p className="no-videos">No {showAll ? "talks" : "videos"} found.</p>
      ) : null}

      {countWithVideo < count ? (
        <Field>
          <Control>
            <Checkbox
              checked={!!showAll}
              onChange={(e) => setShowAll(e.target.checked)}
            />{" "}
            {countWithVideo > 0 ? "Also show " : "Show "}
            talks that have no video currently available (
            {count - countWithVideo})
          </Control>
        </Field>
      ) : null}

      {items.length > 0 ? (
        <Columns multiline>
          <Columns.Column
            size={widths.dateTime}
            className="talk-list-header date-time"
          >
            <SortableHeader
              forWhich="datetime"
              label="Date/time"
              intro="Sort by date/time"
            />
          </Columns.Column>

          {singleEvent ? null : (
            <Columns.Column
              size={widths.event}
              className="talk-list-header event"
            >
              <SortableHeader
                forWhich="event_title"
                label="Event"
                intro="Sort by event"
                dateIntro="For talks in the same event:"
              />
            </Columns.Column>
          )}

          <Columns.Column
            size={widths.speaker}
            className="talk-list-header speaker"
          >
            <SortableHeader
              forWhich="speaker_text"
              label="Speaker"
              intro="Sort by speaker name"
              dateIntro="For talks with the same speaker name:"
            />
          </Columns.Column>

          <Columns.Column
            size={widths.title}
            className="talk-list-header talk-title"
          >
            <SortableHeader
              forWhich="title"
              label="Title"
              intro="Sort by talk title"
              dateIntro="For talks with the same title:"
            />
          </Columns.Column>

          {items.map((item) => (
            <DisplayTalk {...item} {...{ flags, widths }} key={item.id} />
          ))}
        </Columns>
      ) : null}
    </Fragment>
  );
}

export default TalkList;
