import React from "react";
import { Link } from "react-router-dom";

export function NavbarLink(props) {
  const { to, dropdown, children, className, ...linkProps } = props;
  const type = "navbar-" + (dropdown ? "link" : "item");
  return (
    <Link
      className={type + (className ? " " + className : "")}
      to={to}
      onClick={(event) => event.currentTarget.blur()}
      {...linkProps}
    >
      {props.children}
    </Link>
  );
}

export default NavbarLink;
