import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectAuth,
  setLoggedOut,
  initiateStaffLogin,
} from "redux/features/auth";
import Hero from "react-bulma-components/lib/components/hero";
import "util/fix-react-bulma.sass";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import Box from "react-bulma-components/lib/components/box";
import Content from "react-bulma-components/lib/components/content";
import Message from "react-bulma-components/lib/components/message";

import Button from "react-bulma-components/lib/components/button";
import IconButton from "util/iconButton";

import {
  Field,
  Control,
  Label,
  Input,
} from "react-bulma-components/lib/components/form";

const defaultPrompts = [["Password", "password"]];

function StaffLogin(props) {
  const auth = useSelector(selectAuth);
  const dispatch = useDispatch();
  const [alerts, setAlerts] = useState([]);

  const defaultUsernameParameter = "username";

  const [usernameParameter, setUsernameParameter] = useState(
    defaultUsernameParameter
  );

  const [prompts, setPrompts] = useState(defaultPrompts);
  const [credentials, setCredentials] = useState({});

  useEffect(() => {
    if (!auth.failed) return;

    const details = auth.details;
    const errorDetails = details.errorDetails ? details.errorDetails : {};
    const promptRequirements = errorDetails.prompt_requirements || {};
    setAlerts(
      promptRequirements.alerts && promptRequirements.alerts.length > 0
        ? promptRequirements.alerts
        : [details.errorMessage || "Authentication failed"]
    );

    const newUsernameParameter =
      promptRequirements.username_parameter || defaultUsernameParameter;
    setUsernameParameter(newUsernameParameter);

    const newPrompts = promptRequirements.credentials_prompts || defaultPrompts;
    setPrompts(newPrompts);

    const previousCredentials = errorDetails.staff_credentials || {};
    const nextCredentials = {};
    (promptRequirements.state_parameters || []).forEach((p) => {
      nextCredentials[p] = previousCredentials[p] || "";
    });
    nextCredentials[newUsernameParameter] =
      previousCredentials[newUsernameParameter] || "";

    newPrompts.forEach(([prompt, param]) => (nextCredentials[param] = ""));
    setCredentials(nextCredentials);
    dispatch(setLoggedOut());
  }, [auth.failed, auth.details, dispatch]);

  const changeCredential = (credential) => (event) => {
    if (credentials[credential] !== "") setAlerts([]);
    let newCredentials = { ...credentials };
    newCredentials[credential] = event.target.value;
    setCredentials(newCredentials);
  };

  const doLogin = (event) => {
    dispatch(initiateStaffLogin(credentials));
  };

  const focusCredentials = (index) => (event) => {
    if (event.key === "Enter") {
      if (index >= prompts.length) doLogin(event);
      else document.querySelector(".credentials-" + index).focus();
    }
  };

  return (
    <Hero hasNavbar color="success" size="fullheight">
      <Hero.Body>
        <Container>
          <Columns centered>
            <Columns.Column narrow>
              <Box>
                <Content>
                  <p>
                    Log in with a Fields staff account to access video editing
                    features.
                  </p>
                </Content>

                {alerts.map((msg, index) => (
                  <Message color="danger" key={"alert" + index}>
                    <Message.Body> {msg} </Message.Body>
                  </Message>
                ))}

                <Field key={usernameParameter}>
                  <Label>Username</Label>
                  <Control>
                    <Input
                      value={credentials[usernameParameter]}
                      placeholder="Your Fields staff username"
                      onChange={changeCredential(usernameParameter)}
                      onKeyUp={focusCredentials(0)}
                    />
                  </Control>
                </Field>
                {prompts.map(([prompt, param], index) => (
                  <Field key={param + "field"}>
                    <Label>{prompt}</Label>
                    <Control>
                      <Input
                        className={"credentials-" + index}
                        type="password"
                        value={credentials[param]}
                        onChange={changeCredential(param)}
                        onKeyUp={focusCredentials(1 + index)}
                      />
                    </Control>
                  </Field>
                ))}

                <Button.Group position="centered" hasAddons>
                  <IconButton
                    className="login-button"
                    color="success"
                    onClick={doLogin}
                    size="small"
                    icon="check"
                  >
                    Log In
                  </IconButton>
                </Button.Group>
              </Box>
            </Columns.Column>
          </Columns>
        </Container>
      </Hero.Body>
    </Hero>
  );
}

export default StaffLogin;
