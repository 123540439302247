import React, { useEffect, useState, useRef, useCallback } from "react";
import { TimingObject } from "timing-object";
import { setTimingsrc } from "timingsrc";
import Slider from "rc-slider";
import Handle from "rc-slider/lib/Handle";
import formatDuration from "format-duration";
import { withResizeDetector } from "react-resize-detector";
import Columns from "react-bulma-components/lib/components/columns";
import IconButton from "util/iconButton";
import Icon from "util/icon";
import WithTooltip from "util/tooltip";
import { DropdownFramework } from "util/dropdown";
import { reportViewingSlice } from "redux/features/reportViewing";
import { useDispatch } from "react-redux";

import { FaPlay, FaPause } from "react-icons/fa";
import {
  MdReplay5,
  MdReplay30,
  MdForward10,
  MdForward30,
} from "react-icons/md";
import {
  IoVolumeMedium,
  IoVolumeMute,
  IoVolumeLow,
  IoVolumeHigh,
} from "react-icons/io5";

export function useSyncState(startTime = 0, duration = Infinity) {
  const [volume, setVolume] = useState(0.5);
  const [muted, setMuted] = useState(false);

  // The playback time, as it advances through the video, is maintained
  // by the timing source object and updates many times per second.

  // We do not want the controlling component updating that often but we
  // do want it updating often enough to move the slider knob and update
  // the slider tooltip display (which is rounded to the nearest second),
  // so we have a state variable that holds a rounded value of the playback
  // time, rounded to the nearest slider position or whole second or
  // time when a change in layout is due, whichever is closest.

  // We don't ever use the value of the state variable; all we have is a
  // routine to set it, which forces a component re-render every time that
  // rounded value changes.
  const [, setObservedTime] = useState(null);

  // We keep our state in a mutable ref, so that internal changes don't
  // trigger re-renders. However, when a start time / duration is encountered
  // we replace our state with a new object and create a new timing object.

  const ref = useRef({});
  let state = ref.current;
  if (
    !state.timingObject ||
    state.startTime !== startTime ||
    state.duration !== duration
  ) {
    ref.current = { ...state };
    state = ref.current;
    state.timingObject = new TimingObject(
      { position: startTime, velocity: 0 },
      startTime,
      duration < Infinity ? duration + startTime : Infinity
    );
    state.startTime = startTime;
    state.duration = duration;
  }

  state.volume = volume;
  state.setVolume = setVolume;
  state.muted = muted;
  state.setMuted = setMuted;
  state.setObservedTime = setObservedTime;
  return state;
}

/* When using a timing source object to direct a media player, we install
   a custom routine to adjust the timing vector before it is passed to
   the player. The adjustments are the following:

   (1) The media file may have discontinuous segments or may not last the
       entire span of the presentation (e.g. when slides are activated and
       deactivated). Don't try to seek to a position that isn't in one of
       the available play ranges; seek to the closest range endpoint instead.

   (2) When the media doesn't start at the same zero-time start point as the
       overall timeline, offset by the start amount.

   (3) When paused, the media element (at least on Firefox)  might not stop
       at exactly the requested time, so there is a constantly spinning
       cpu as the media tries to go to the requested time and then ends back
       up at its previous timestamp and tries again. We fix this by not
       trying to re-adjust the media position if it is paused and within a
       certain threshold of the desired position.

*/

function prepareUpdateVector(player, inVector, start) {
  // Offset by the start amount if specified and on-zero.
  const vector = start
    ? { ...inVector, position: inVector.position - start }
    : inVector;

  // Make sure we can seek to requested position. Check seekable ranges,
  // with "position" set to the latest known-valid position. If desired
  // updated position is not in a seekable range, seek to "position"
  // instead and pause there.
  let position = player.currentTime;
  if (position === vector.position) return vector;
  let rangeIndex = 0;
  const seekable = player.seekable;
  while (
    rangeIndex < seekable.length &&
    seekable.end(rangeIndex) < vector.position
  ) {
    position = seekable.end(rangeIndex);
    ++rangeIndex;
  }
  if (
    rangeIndex >= seekable.length ||
    seekable.start(rangeIndex) > vector.position
  )
    return { ...vector, position, velocity: 0 };

  // If paused, don't adjust position if within threshold of desired position
  const THRESHOLD = 0.2;
  if (
    !player.playing &&
    vector.velocity === 0 &&
    player.currentTime > vector.position - THRESHOLD &&
    player.currentTime < vector.position + THRESHOLD
  ) {
    return { ...vector, position: player.currentTime };
  }
  // To do: if we are set to playing but seeking is taking longer than
  // expected due to corrupted video, wait for player to finish seeking.
  return vector;
}

/* When we unmount a media element, it might keep a connection open to
   the media URL (perhaps in case the element would later be reactivated).
   With multiple feeds from the same server, this can cause the browser's
   limit on concurrent connections to be hit and then no more videos can
   be launched. We therefore detach the media element from the source
   before unmounting. The prescribed way to do this according to the
   HTML specs is to remove the src attributes (and source child elements,
   but we don't have any) and call the load() method.

   We also, of course, remove our timing source subscriber.
*/

function cleanUpPlayer({ player, timingSrcRemover }) {
  if (timingSrcRemover) timingSrcRemover();
  if (player) {
    player.removeAttribute("src");
    player.load();
  }
}

export function Player(props) {
  const {
    syncState,
    isAudioOnly,
    hasAudio,
    volume,
    muted,
    start,
    ...elementProps
  } = props;
  const { timingObject } = syncState;
  const { src } = elementProps;

  const [playerState, setPlayerState] = useState({});

  const mountOrUnmountPlayer = useCallback(
    (player) => {
      if (player) {
        // Mounted. Attach timing source
        const timingSrcRemover = setTimingsrc(player, timingObject, (vector) =>
          prepareUpdateVector(player, vector, start)
        );

        // Set the new player state. If an old player was left in the state
        // having not been properly unmounted, remove it.
        setPlayerState((oldState) => {
          if (oldState.player || oldState.timingSrcRemover) {
            console.error("Old player did not get cleaned up.");
            cleanUpPlayer(oldState);
          }
          return { player, timingSrcRemover };
        });
      } else {
        // Unmounted. Clean up old player (if any);
        setPlayerState((oldState) => {
          cleanUpPlayer(oldState);
          return {};
        });
      }
    },
    [timingObject, start]
  );

  useEffect(() => {
    const id = window.setInterval(() => {
      // To do: check health of player....
      //  const { player } = playerState;
      // ...
    }, 1000);
    return () => window.clearInterval(id);
  }, [playerState]);

  useEffect(() => {
    if (playerState.player && hasAudio) playerState.player.volume = volume;
  });

  return !src ? null : isAudioOnly ? (
    <audio ref={mountOrUnmountPlayer} {...elementProps} {...{ muted }} />
  ) : (
    <video
      key={src}
      ref={mountOrUnmountPlayer}
      {...elementProps}
      {...(hasAudio ? { muted } : {})}
    />
  );
}

function ResizableSyncController(props) {
  const {
    state,
    width,
    targetRef,
    settingsMenu,
    refreshAt,
    startHandlerRef,
    id,
    started,
    setStarted,
    smallIcons,
  } = props;
  const { startTime, duration, timingObject, setObservedTime } = state;
  const sliderUnits = Math.round(width || 100);

  const timeFromSlider = useCallback(
    (pos) => startTime + (duration * pos) / sliderUnits,
    [startTime, duration, sliderUnits]
  );

  const sliderPosFromTime = useCallback(
    (time) => {
      const endTime = startTime + duration;
      const realTime =
        time < startTime ? startTime : time > endTime ? endTime : time;
      return Math.round(((realTime - startTime) * sliderUnits) / duration);
    },
    [startTime, duration, sliderUnits]
  );

  const roundTime = useCallback(
    (time) => {
      const wholeSeconds = Math.round(time);
      const sliderTime = timeFromSlider(sliderPosFromTime(time));
      return Math.abs(sliderTime - time) < Math.abs(wholeSeconds - time)
        ? sliderTime
        : wholeSeconds;
    },
    [timeFromSlider, sliderPosFromTime]
  );

  const currentTime = timingObject.query().position;
  const sliderPos = sliderPosFromTime(currentTime);

  const [playing, setPlaying] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (playing && timingObject.query().velocity > 0)
      dispatch(
        reportViewingSlice.actions.setViewed({
          talkId: id,
          sessionStart: started,
          position: Math.round(
            (9 * (timingObject.query().position - startTime)) / duration
          ),
        })
      );
    dispatch(reportViewingSlice.asyncActions.reportUnrecorded(id, started));
  });

  // useEffect(() => checkSlideState());
  useEffect(() => {
    if (duration === Infinity) return null;
    const interval = Math.min(1000, (1000 * duration) / sliderUnits);
    const id = window.setInterval(() => {
      setObservedTime(roundTime(timingObject.query().position));
    }, interval);
    return () => window.clearInterval(id);
  }, [setObservedTime, roundTime, timingObject, duration, sliderUnits]);

  const now = Date.now();
  const imminentSlideStateChange =
    refreshAt < startTime + duration &&
    sliderPosFromTime(refreshAt) <= sliderPos + 1
      ? Math.floor(1000 * (refreshAt - currentTime)) + now
      : null;
  useEffect(() => {
    if (imminentSlideStateChange) {
      const id = window.setTimeout(
        () => setObservedTime(timingObject.query().position),
        imminentSlideStateChange - now
      );
      return () => window.clearTimeout(id);
    } else return null;
  }, [setObservedTime, timingObject, imminentSlideStateChange, now]);

  const onStartSeeking = useCallback(
    (pos) => {
      const time = timeFromSlider(pos);
      timingObject.update({ position: time, velocity: 0.0 });
      setObservedTime(time);
    },
    [timingObject, timeFromSlider, setObservedTime]
  );

  const onSeek = useCallback(
    (pos) => {
      const time = timeFromSlider(pos);
      timingObject.update({ position: time, velocity: 0.0 });
      setObservedTime(time);
    },
    [timingObject, timeFromSlider, setObservedTime]
  );

  const onFinishSeeking = useCallback(
    (pos) => {
      const time = timeFromSlider(pos);

      timingObject.update({
        position: time,
        velocity: playing && time < startTime + duration ? 1.0 : 0.0,
      });
      setObservedTime(time);
    },
    [
      timingObject,
      timeFromSlider,
      playing,
      startTime,
      duration,
      setObservedTime,
    ]
  );

  const play = useCallback(() => {
    const time = timingObject.query().position;
    /* For some reason as yet undetermined, resumption of playback is
       delayed by a second or two unless we go back by about this amount. */
    const GO_BACK = 1.1;
    if (time < startTime + duration) {
      timingObject.update({
        position: time < startTime + GO_BACK ? startTime : time - GO_BACK,
        velocity: 1.0,
      });
      setStarted(Math.floor(Date.now() / 1000));
      setPlaying(true);
    }
  }, [timingObject, startTime, duration, setStarted]);

  startHandlerRef.current = play;

  const pause = useCallback(() => {
    timingObject.update({ velocity: 0.0 });
    setPlaying(false);
  }, [timingObject]);

  const { volume, setVolume, muted, setMuted } = state;

  // The rc-slider package has an unreliable tooltip (bug reports online
  // pin the blame on improper dependency version tracking between rc-slider
  // and other rc-... components used for the tooltip) so do our own
  // Bulma tooltip by using a custom Handle. We do it raw rather than with
  // our conenience WithTooltip component because its active behaviour
  // is controlled by dragging the handle, not by WithTooltip's hover/touch
  // conventions.

  const currentSeconds = Math.round(currentTime - startTime);
  const generatePositionHandle = useCallback(
    ({ children, className = "", dragging, ...props }) => {
      return (
        <Handle
          data-tooltip={
            formatDuration(1000 * currentSeconds) +
            " / " +
            formatDuration(1000 * Math.round(duration))
          }
          className={
            "has-tooltip-arrow" +
            (dragging ? " has-tooltip-active" : "") +
            (className !== "" ? " " + className : "")
          }
          {...props}
        >
          {children}
        </Handle>
      );
    },
    [duration, currentSeconds]
  );

  const changeTime = (delta) => () => {
    const time = timingObject.query().position;
    const newTime = time + delta;
    timingObject.update({
      position:
        newTime < startTime
          ? startTime
          : newTime > startTime + duration
          ? startTime + duration
          : newTime,
    });
    setObservedTime(newTime);
  };

  const seekSize = smallIcons ? "large" : "larger";
  const playPauseSize = smallIcons ? undefined : "medium";
  const volumeSize = smallIcons ? "medium" : "large";

  // Live controller to be implemented later
  if (duration === Infinity) return null;

  return (
    <Columns breakpoint="mobile" className="is-vcentered sync-controls">
      <Columns.Column narrow>
        <WithTooltip
          component={IconButton}
          className="no-active"
          color="success"
          size={playPauseSize}
          onClick={playing ? pause : play}
          icon={playing ? FaPause : FaPlay}
          tip={playing ? "Pause" : "Play"}
        />
      </Columns.Column>
      <Columns.Column className="seek-controls">
        <div className="replay-icons">
          {" "}
          <WithTooltip
            component={IconButton}
            color="success"
            rounded
            size={seekSize}
            icon={MdReplay30}
            onClick={changeTime(-30)}
            tip="Go back 30 seconds"
          />
          <WithTooltip
            component={IconButton}
            color="success"
            rounded
            size={seekSize}
            icon={MdReplay5}
            onClick={changeTime(-5)}
            tip="Go back 5 seconds"
          />
        </div>
        <div ref={targetRef} className="seek-slider">
          <Slider
            value={sliderPos}
            onChange={onSeek}
            onBeforeChange={onStartSeeking}
            onAfterChange={onFinishSeeking}
            min={0}
            max={sliderUnits}
            handle={generatePositionHandle}
          />
        </div>
        <div className="forward-icons">
          <WithTooltip
            component={IconButton}
            color="success"
            rounded
            size={seekSize}
            icon={MdForward10}
            onClick={changeTime(10)}
            tip="Go forward 10 seconds"
          />
          <WithTooltip
            component={IconButton}
            color="success"
            rounded
            size={seekSize}
            icon={MdForward30}
            onClick={changeTime(30)}
            tip="Go forward 30 seconds"
          />
        </div>
      </Columns.Column>
      <Columns.Column narrow>
        <DropdownFramework
          className="volume-control"
          trigger={
            <Icon icon={IoVolumeMedium} size={volumeSize} color="white" />
          }
        >
          <div className="body">
            <div className="endpoint">
              <Icon icon={IoVolumeHigh} size={volumeSize} color="white" />
            </div>
            <div className="slider">
              <Slider
                value={Math.floor(100 * Math.sqrt(volume))}
                onChange={(pos) => setVolume((pos / 100) * (pos / 100))}
                min={0}
                max={100}
                vertical
              />
            </div>
            <div className="endpoint">
              <Icon icon={IoVolumeLow} size={volumeSize} color="white" />
            </div>
          </div>
        </DropdownFramework>
      </Columns.Column>
      <Columns.Column narrow>
        <WithTooltip
          component={IconButton}
          icon={IoVolumeMute}
          size={volumeSize}
          color={muted ? "warning" : "success"}
          tip={muted ? "Unmute" : "Mute"}
          rounded
          onClick={(e) => setMuted((m) => !m)}
        />
      </Columns.Column>
      <Columns.Column narrow className="keep-right">
        {settingsMenu}
      </Columns.Column>
    </Columns>
  );
}

export const SyncController = withResizeDetector(ResizableSyncController);
