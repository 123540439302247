import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { browseSlice } from "redux/features/browse";
import { useSelector, useDispatch } from "react-redux";
import TopBar from "components/topbar";
import TalkList from "components/talkList";

import Box from "react-bulma-components/lib/components/box";
import Loading from "util/loading";
import StatusNotice from "components/statusNotice";
import ErrorMessage from "util/errorMessage";
import dayjs from "dayjs";

function displayEventDates(startDate, endDate) {
  if (!startDate || !endDate) return "";
  const startDayJS = dayjs(startDate);
  const endDayJS = dayjs(endDate);
  if (startDayJS.year() !== endDayJS.year())
    return (
      startDayJS.format("MMMM D, YYYY") +
      " - " +
      endDayJS.format("MMMM D, YYYY")
    );
  else if (startDayJS.month() !== endDayJS.month())
    return (
      startDayJS.format("MMMM D") + " - " + endDayJS.format("MMMM D, YYYY")
    );
  else if (startDayJS.day() !== endDayJS.day())
    return startDayJS.format("MMMM D") + "-" + endDayJS.format("D, YYYY");
  else return startDayJS.format("MMMM D, YYYY");
}

function ListPage(props) {
  const { what } = props;
  const { id } = useParams();

  const browseKey = `${what}/${id}`;

  const loadState = useSelector(browseSlice.selector)[browseKey] || {
    uninitialized: true,
  };
  const loading = loadState.uninitialized || loadState.loading;
  const results = loadState.results || {};
  const dispatch = useDispatch();
  useEffect(() => {
    if (loadState.failed)
      // Clear failure state when component unmounts
      return () => dispatch(browseSlice.actions.clear(browseKey));
    else return null;
  }, [browseKey, dispatch, loadState.failed]);

  useEffect(() => {
    if (!loadState.failed && !loadState.results && !loadState.loading) {
      dispatch(browseSlice.asyncActions.load(browseKey));
    }
  });

  const allTalks = results.talks || [];
  const talks =
    what === "speaker"
      ? allTalks.filter(({ speakers }) =>
          speakers.map((s) => s.id).includes(id)
        )
      : allTalks;
  const otherTalks =
    what === "speaker"
      ? allTalks.filter(
          ({ speakers }) => !speakers.map((s) => s.id).includes(id)
        )
      : [];
  const [talkPrefix, otherTalkPrefix] =
    what === "speaker" && otherTalks.length > 0
      ? [
          "The following talks/videos are directly associated " +
            "with this speaker",
          "The following talks/videos are not directly associated with " +
            "this speaker but have a matching speaker name; they may or " +
            "may not be by the same person",
        ]
      : [null, null];

  const singleYear =
    what === "event" &&
    results.start_date &&
    results.end_date &&
    dayjs(results.start_date).year() === dayjs(results.end_date).year();

  const singleEvent = what === "event" && !results.contains_events;

  return (
    <Fragment>
      <TopBar
        metadataState={{
          loading,
          metadata: {
            heading:
              what === "speaker" ? `Talks by ${results.name}` : results.title,
            subheading:
              what === "event" &&
              results.start_date &&
              results.end_date &&
              !results.date_is_in_title
                ? displayEventDates(results.start_date, results.end_date)
                : null,
          },
        }}
      />
      <div className="list-body">
        <StatusNotice />
        <Box className="list-result-container">
          {loading ? (
            <div>
              Loading video list . . . <Loading />
            </div>
          ) : loadState.failed ? (
            <ErrorMessage>
              Error loading video list: {loadState.failed.message}
            </ErrorMessage>
          ) : (
            <Fragment>
              {talkPrefix ? (
                <p className="talk-list-section">{talkPrefix}</p>
              ) : null}
              <TalkList talks={talks} {...{ singleEvent, singleYear }} />
              {otherTalkPrefix ? (
                <p className="talk-list-section">{otherTalkPrefix}</p>
              ) : null}
              {otherTalks.length > 0 ? (
                <TalkList talks={otherTalks} {...{ singleEvent, singleYear }} />
              ) : null}
            </Fragment>
          )}
        </Box>
      </div>
    </Fragment>
  );
}

export default ListPage;
