import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { apiPayloadCreator } from "util/api";

const AUTH_REFRESH_INTERVAL = 3600 * 1000;

export const authStatus = {
  unknown: "unknown",
  loggedOut: "loggedOut",
  resuming: "resuming",
  loggingIn: "loggingIn",
  authenticatedStaff: "staff",
  authenticatedExternal: "external",
};

const authApiCall = createAsyncThunk(
  "auth/apiCall",
  apiPayloadCreator(
    ["auth", "/authentication"],
    "get",
    (success, arg, thunkAPI) => {
      const { allowStaff, allowExternal } = arg;
      if (allowStaff && success.staff_username && success.token) return success;
      if (allowExternal && success.person_id && success.token) return success;
      return thunkAPI.rejectWithValue({
        message: "Unexpected authentication type",
        details: { allowed: { allowStaff, allowExternal }, got: success },
      });
    }
  )
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    status: authStatus.unknown,
    satatusTime: null,
    failed: false,
    token: null,
    details: {},
  },

  reducers: {
    setLoggedOut: (state) => {
      state.status = authStatus.loggedOut;
      state.failed = false;
      state.statusTime = null;
      state.details = {};
      state.token = null;
      window.sessionStorage.token = null;
    },
  },
  extraReducers: {
    [authApiCall.pending]: (state, action) => {
      const { refreshingToken, loggingIn } = action.meta.arg;
      if (refreshingToken) return;
      state.status = loggingIn ? authStatus.loggingIn : authStatus.resuming;
      state.failed = false;
      state.details = {};
    },

    [authApiCall.fulfilled]: (state, action) => {
      if (action.payload.staff_username) {
        state.status = authStatus.authenticatedStaff;
        state.details = {
          staffUsername: action.payload.staff_username,
        };
      } else {
        state.status = authStatus.authenticatedExternal;
        state.details = {
          publicId: action.payload.person_id,
        };
      }
      state.statusTime = Date.now();
      state.failed = false;
      state.token = action.payload.token;
      window.sessionStorage.token = action.payload.token;
    },

    [authApiCall.rejected]: (state, action) => {
      const { refreshingToken, loggingIn } = action.meta.arg;
      if (refreshingToken) return;
      state.failed = loggingIn ? true : false;
      state.status = authStatus.loggedOut;
      state.statusTime = Date.now();
      state.details = {
        errorMessage: action.payload.message || "Authentication failed",
        errorDetails: action.payload.details,
      };
      state.token = null;
      window.sessionStorage.token = null;
    },
  },
});

export const { setLoggedOut } = authSlice.actions;

export const selectAuth = (state) => state.auth;

export default authSlice.reducer;

export const checkAuth = (token, allow) => (dispatch) => {
  return dispatch(authApiCall({ path: "/check", authToken: token, ...allow }));
};

export const checkSessionAuth = (allow) => (dispatch) => {
  return dispatch(authApiCall({ path: "/check", ...allow }));
};

export const initiateStaffLogin = (credentials) => (dispatch) => {
  return dispatch(
    authApiCall({
      path: "/login/staff",
      method: "post",
      data: { staff_credentials: credentials },
      allowStaff: true,
      loggingIn: true,
    })
  );
};

export const initiateExternalLogin = (credentials) => (dispatch) => {
  return dispatch(
    authApiCall({
      path: "/login/external",
      method: "post",
      data: credentials,
      allowExternal: true,
      loggingIn: true,
    })
  );
};

export function Auth(props) {
  const auth = useSelector(selectAuth);
  const dispatch = useDispatch();
  const { allowStaff, allowExternal } = props;
  const invalid =
    (auth.status === authStatus.authenticatedStaff && !allowStaff) ||
    (auth.status === authStatus.authenticatedExternal && !allowExternal);

  // If redux store is refreshed after a browser resumption following
  // a gap, our token may no longer be valid.
  const expired =
    (auth.status === authStatus.authenticatedStaff ||
      auth.status === authStatus.authenticatedExternal) &&
    !(auth.statusTime > Date.now() - 2 * AUTH_REFRESH_INTERVAL);

  useEffect(() => {
    if (auth.status === authStatus.unknown) {
      const token = window.sessionStorage.token;
      dispatch(
        token
          ? checkAuth(token, { allowStaff, allowExternal })
          : checkSessionAuth({ allowStaff, allowExternal })
      );
    }
    if (invalid || expired) dispatch(setLoggedOut());
    return;
  });

  const authenticated =
    (auth.status === authStatus.authenticatedStaff ||
      auth.status === authStatus.authenticatedExternal) &&
    !invalid;

  useEffect(() => {
    let timerId = null;
    if (authenticated && auth.token) {
      timerId = window.setInterval(
        () =>
          dispatch(
            checkAuth(auth.token, {
              allowStaff,
              allowExternal,
              refreshingToken: true,
            })
          ),
        AUTH_REFRESH_INTERVAL
      );
    }
    return () => {
      if (timerId !== null) window.clearInterval(timerId);
    };
  }, [authenticated, auth.token, dispatch, allowStaff, allowExternal]);
  const authenticating = auth.status === authStatus.loggingIn;

  if (authenticated) return props.ifAuthenticated();
  if (authenticating) return props.ifThinking("authenticating");
  if (auth.status === authStatus.loggedOut) return props.ifUnauthenticated();
  return props.ifThinking("initializing");
}
