import React from "react";
import Columns from "react-bulma-components/lib/components/columns";

import Icon from "util/icon";
import { FaVideo, FaListAlt } from "react-icons/fa";

export const CameraIcon = ({ size, outerSize }) => (
  <Icon outerSize={outerSize} size={size} icon={FaVideo} />
);

export const NoCameraIcon = ({ size, outerSize = size }) => (
  <Icon
    outerSize={outerSize}
    size={size}
    negated
    negationColor="danger"
    icon={FaVideo}
  />
);

export const SlidesIcon = ({ size, outerSize }) => (
  <Icon outerSize={outerSize} size={size} icon={FaListAlt} />
);

export const NoSlidesIcon = ({ size, outerSize = size }) => (
  <Icon
    outerSize={outerSize}
    size={size}
    negated
    negationColor="danger"
    icon={FaListAlt}
  />
);

const half = (IconComponent) => (
  <Columns.Column size="half" className="keep-wide">
    <div className="layout-icon">
      <IconComponent size="medium" />
    </div>
  </Columns.Column>
);

const small = (IconComponent1, IconComponent2) => (
  <Columns.Column size="one-third" className="keep-wide">
    <div className="layout-icon">
      <IconComponent1
        outerSize={IconComponent2 ? "small" : null}
        size="small"
      />
    </div>
    {IconComponent2 ? (
      <div className="layout-icon">
        <IconComponent2 outerSize="small" size="small" />
      </div>
    ) : null}
  </Columns.Column>
);

const large = (IconComponent) => (
  <Columns.Column size="two-thirds">
    <div className="layout-icon">
      <IconComponent outerSize="medium" size="large" />
    </div>
  </Columns.Column>
);

const buildLabel = (text, part1, part2, part3) => (
  <Columns breakpoint="mobile" multiline={false} className="is-vcentered">
    <Columns.Column size={5}>
      <Columns multiline={false} gapless breakpoint="mobile">
        {part1}
        {part2}
        {part3 ? part3 : null}
      </Columns>
    </Columns.Column>
    <Columns.Column size={7}>{text}</Columns.Column>
  </Columns>
);

const layoutLabels = { half, small, large, buildLabel };
export default layoutLabels;
