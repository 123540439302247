import React from "react";
import { homepage } from "config";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { UndoTemporaryRedirect } from "util/temporaryRedirect";
import CommonApp from "commonApp";

function AuthenticatedApp(props) {
  return (
    <Router basename={homepage}>
      <Switch>
        <Route path="/staff">
          <UndoTemporaryRedirect />
        </Route>
        <Route>
          <CommonApp authenticated />
        </Route>
      </Switch>
    </Router>
  );
}
export default AuthenticatedApp;
