import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiPayloadCreator } from "util/api";

const apiCall = createAsyncThunk(
  "metadata/apiCall",
  apiPayloadCreator(["metadata", "videos/browse"], "get")
);

export const browseSlice = createSlice({
  name: "browse",
  initialState: {},
  reducers: {
    clear: (state, action) => {
      const browseKey = action.payload;
      delete state[browseKey];
    },
  },

  extraReducers: {
    [apiCall.pending]: (state, action) => {
      const {
        arg: { browseKey },
      } = action.meta;
      if (!state[browseKey]) state[browseKey] = {};
      state[browseKey].loading = true;
      state[browseKey].failed = null;
    },
    [apiCall.fulfilled]: (state, action) => {
      const {
        arg: { browseKey },
      } = action.meta;
      if (!state[browseKey]) state[browseKey] = {};
      state[browseKey].loading = false;
      state[browseKey].failed = null;
      state[browseKey].results = action.payload;
    },
    [apiCall.rejected]: (state, action) => {
      const {
        arg: { browseKey },
      } = action.meta;
      if (!state[browseKey]) state[browseKey] = {};
      state[browseKey].loading = false;
      state[browseKey].failed = action.payload;
    },
  },
});

browseSlice.selector = (store) => store.browse;

browseSlice.asyncActions = {
  load: (browseKey) => (dispatch) => {
    dispatch(
      apiCall({
        browseKey: browseKey,
        path: "/" + browseKey,
      })
    );
  },
};

export default browseSlice.reducer;
