import React from "react";
import {
  Field,
  Control,
  Input,
} from "react-bulma-components/lib/components/form";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";

export const searchHeadingClass = "is-size-3 has-text-weight-bold";

export function SearchInput(props) {
  const { searchTerm, setSearchTerm, autoUpdate } = props;
  return (
    <Container className="search-input-container">
      <Columns>
        <Columns.Column narrow className={searchHeadingClass}>
          Search for
        </Columns.Column>
        <Columns.Column>
          <Field>
            <Control>
              <Input
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
            </Control>
            <p>
              You can enter part of the talk title, speaker's name or the event
              name.
            </p>
            {autoUpdate ? (
              <p>Results below will automatically update as you type.</p>
            ) : null}
          </Field>
        </Columns.Column>
      </Columns>
    </Container>
  );
}

export default SearchInput;
