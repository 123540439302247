import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { withResizeDetector } from "react-resize-detector";

import { useSyncState, Player, SyncController } from "components/sync";
import VideoPane from "components/videoPane";
import { mediaBaseURL } from "config";

import Dropdown from "util/dropdown";
import Button from "react-bulma-components/lib/components/button";
import { numericAscending } from "util/sort";

import Icon from "util/icon";
import IconButton from "util/iconButton";
import { FaVideo, FaListAlt, FaPlay, FaCog } from "react-icons/fa";

import allLayouts from "components/layouts";
import {
  useClosestResolutionFinder,
  getDimensionsForResolution,
} from "tools/feedDimensions";
import { useResolutionSelection } from "redux/features/resolutions";

function slideInfoAtTime(time, mediaInfo) {
  const feeds = mediaInfo.feeds;
  return Object.keys(feeds)
    .filter((feedName) => feedName.startsWith("slides"))
    .sort()
    .map((feedName) => {
      const ranges = feeds[feedName].playRanges || [
        [mediaInfo.start, mediaInfo.end],
      ];
      const range = ranges.filter(([start, end]) => end >= time)[0];
      const playing = range && range[0] <= time ? true : false;
      return {
        feedName,
        playing,
        nextTransition: playing ? range[1] : range ? range[0] : mediaInfo.end,
      };
    });
}

const layoutsByNumSlides = (numSlides) =>
  allLayouts.filter((l) => l.numSlides === numSlides);

const layoutIndex = Object.fromEntries(allLayouts.map((l) => [l.id, l]));

function buildPaneLayout({
  layoutSpec,
  feedNames,
  allFeedDetails,
  width,
  height,
  currentZooms,
  getClosestResolution,
}) {
  const columns = ["left", "centre", "right"].map((columnName) => {
    const [size = 0, topFeedCode, bottomFeedCode] = layoutSpec[columnName];
    const top = { feedName: topFeedCode ? feedNames[topFeedCode] : null };
    const bottom = {
      feedName: bottomFeedCode ? feedNames[bottomFeedCode] : null,
    };
    return { size, top, bottom };
  });

  const [left, centre, right] = columns;
  const totalSize = left.size + centre.size + right.size;
  columns.forEach((column) => {
    const { size, top, bottom } = column;
    column.targetWidth = (width * size) / totalSize;
    column.targetHeight = 0;
    const panes = [top, bottom].filter((pane) => !!pane.feedName);
    panes.forEach((pane) => {
      const feedDetails = allFeedDetails[pane.feedName];
      const resolution = getClosestResolution(feedDetails, column.targetWidth);
      const zoomLevel = currentZooms[pane.feedName] || 0;
      const { maxZoomLevel, heightByLevel } = getDimensionsForResolution(
        feedDetails,
        resolution
      );
      pane.aspectRatio =
        resolution / heightByLevel[Math.min(zoomLevel, maxZoomLevel)];
      pane.targetHeight = column.targetWidth / pane.aspectRatio;
      column.targetHeight = column.targetHeight + pane.targetHeight;
      pane.resolution = resolution;
    });
    if (column.targetHeight > height) {
      column.height = height;
      column.width = Math.floor(
        (column.targetWidth * height) / column.targetHeight
      );
      panes.forEach((pane) => {
        pane.height = (pane.targetHeight * height) / column.targetHeight;
      });
      column.expandable = 0;
    } else {
      column.width = column.targetWidth;
      column.expandable = Math.floor(
        (column.targetWidth * height) / column.targetHeight - column.targetWidth
      );
    }
  });
  const space = {};
  const expansionNeeded = () => {
    space.available = width;
    space.expandableShares = 0;
    columns.forEach((column) => {
      if (column.expandable >= 1) space.expandableShares += column.size;
      space.available -= Math.ceil(column.width);
    });
    return (
      space.available >= space.expandableShares && space.expandableShares > 0
    );
  };
  while (expansionNeeded()) {
    columns.forEach((column) => {
      if (column.expandable > 0) {
        const expansion = Math.floor(
          (space.available * column.size) / space.expandableShares
        );
        if (expansion > column.expandable) {
          column.width = column.width + column.expandable;
          column.expandable = 0;
        } else {
          column.width = column.width + expansion;
          column.expandable = column.expandable - expansion;
        }
      }
    });
  }

  columns.forEach((column) => {
    [column.top, column.bottom].forEach((pane) => {
      if (pane.feedName) {
        pane.width = column.width;
        pane.height = column.width / pane.aspectRatio;
        pane.feedDetails = allFeedDetails[pane.feedName];
      }
    });
  });
  return columns;
}

function tryVerticalLayout(
  columns,
  width,
  height,
  currentZooms,
  getClosestResolution
) {
  const [left, centre, right] = columns;
  const totalSize = Math.max(left.size, centre.size, right.size);
  let totalHeight = 0;
  let regularArea = 0;
  let verticalArea = 0;

  columns.forEach((column) => {
    [column.top, column.bottom]
      .filter((pane) => !!pane.feedName)
      .forEach((pane) => {
        regularArea += pane.height * column.width;

        const targetWidth = (width * column.size) / totalSize;
        column.widthIfVertical = targetWidth;
        const resolution = getClosestResolution(pane.feedDetails, targetWidth);
        const zoomLevel = currentZooms[pane.feedName] || 0;
        const { maxZoomLevel, heightByLevel } = getDimensionsForResolution(
          pane.feedDetails,
          resolution
        );
        const aspectRatio =
          resolution / heightByLevel[Math.min(zoomLevel, maxZoomLevel)];
        const paneHeight = targetWidth / aspectRatio;
        pane.heightIfVertical = paneHeight;
        totalHeight += paneHeight;
        verticalArea += targetWidth * paneHeight;
      });
  });
  const scale = Math.min(height / totalHeight, 1);
  if (scale * scale * verticalArea < 1.25 * regularArea) return false;
  columns.forEach((column) => {
    column.width = Math.floor(column.widthIfVertical * scale);
    [column.top, column.bottom].forEach((pane) => {
      if (pane.feedName) {
        pane.width = column.width;
        pane.height = Math.floor(pane.heightIfVertical * scale);
      }
    });
  });
  return true;
}

function PaneDiv({
  width,
  height,
  resolution,
  feedName,
  position,
  syncState,
  feedDetails,
  setCurrentZooms,
  started,
}) {
  return (
    <div
      className={"pane " + position}
      style={{
        width: width ? width + "px" : "0px",
        height: height ? height + "px" : "0px",
      }}
    >
      {feedName ? (
        <VideoPane
          syncState={syncState}
          feedName={feedName}
          feedDetails={feedDetails}
          width={width}
          height={height}
          resolution={resolution}
          setCurrentZooms={setCurrentZooms}
          started={started}
        />
      ) : null}
    </div>
  );
}

function ResizableVideoLayout(props) {
  const {
    width,
    height,
    targetRef,
    allFeedDetails,
    layoutSpec,
    feedNames,
    syncState,
    started,
    startHandlerRef,
    setSmallIcons,
  } = props;

  const [currentZooms, setCurrentZooms] = useState({});
  useEffect(() =>
    setSmallIcons((prev) =>
      prev && width > 600 ? false : !prev & (width < 550) ? true : prev
    )
  );

  const getClosestResolution = useClosestResolutionFinder();
  const [left, centre, right] = width
    ? buildPaneLayout({
        layoutSpec,
        feedNames,
        allFeedDetails,
        width,
        height,
        currentZooms,
        getClosestResolution,
      })
    : [];

  const needVertical =
    width && height
      ? tryVerticalLayout(
          [left, centre, right],
          width,
          height,
          currentZooms,
          getClosestResolution
        )
      : false;

  return (
    <div
      className={"video-layout" + (needVertical ? " vertical" : "")}
      ref={targetRef}
    >
      {width ? (
        <Fragment>
          <div className="layout-column left">
            <PaneDiv
              position="top"
              {...left.top}
              {...{ syncState, setCurrentZooms, started }}
            />
            <PaneDiv
              position="bottom"
              {...left.bottom}
              {...{ syncState, setCurrentZooms, started }}
            />
          </div>

          <div className="layout-column centre">
            <PaneDiv
              position="top"
              {...centre.top}
              {...{ syncState, setCurrentZooms, started }}
            />
            <PaneDiv
              position="bottom"
              {...centre.bottom}
              {...{ syncState, setCurrentZooms, started }}
            />
          </div>

          <div className="layout-column right">
            <PaneDiv
              position="top"
              {...right.top}
              {...{ syncState, setCurrentZooms, started }}
            />
            <PaneDiv
              position="bottom"
              {...right.bottom}
              {...{ syncState, setCurrentZooms, started }}
            />
          </div>
          {started ? null : (
            <div
              className="start-overlay"
              onClick={() => startHandlerRef.current()}
            >
              <div className="inner">
                <FaPlay size="100%" />
              </div>
            </div>
          )}
        </Fragment>
      ) : null}
    </div>
  );
}

const VideoLayout = withResizeDetector(ResizableVideoLayout);

function LayoutManager(props) {
  const { path, isLink, isEmulated, id, mediaInfo } = props;
  const syncState = useSyncState(
    mediaInfo.start,
    mediaInfo.end - mediaInfo.start
  );

  const [smallIcons, setSmallIcons] = useState(false);

  const [started, setStarted] = useState(null);
  const startHandlerRef = useRef(() => {});

  const allFeedDetails = useMemo(() => {
    if (isLink)
      return {
        camera: {
          url: path,
          baseURL: null,
          suffix: null,
          ...mediaInfo.feeds.camera,
        },
      };
    else
      return Object.fromEntries(
        Object.entries(mediaInfo.feeds).map(([feed, details]) => [
          feed,
          {
            url: null,
            baseURL: (isEmulated ? "" : mediaBaseURL) + path + "/" + feed,
            suffix: ".mp4",
            ...details,
          },
        ])
      );
  }, [path, isLink, isEmulated, mediaInfo]);

  const time = syncState.timingObject.query().position;
  const slideInfo = slideInfoAtTime(time, mediaInfo);
  const nextSlideStateChange = slideInfo
    .map((feed) => feed.nextTransition)
    .sort(numericAscending)[0];

  const playingSlideInfo = slideInfo.filter((feed) => feed.playing);
  const slides = playingSlideInfo.map((feed) => feed.feedName);
  const numSlides = slides.length;

  const cameras = ["editedcamera", "camera"].filter(
    (name) => !!mediaInfo.feeds[name]
  );
  const defaultCamera = cameras[0];
  const [selectedCamera, setSelectedCamera] = useState(defaultCamera);

  const camera = mediaInfo.feeds[selectedCamera]
    ? selectedCamera
    : defaultCamera;

  const layouts = layoutsByNumSlides(numSlides);
  const [layoutSelections, setLayoutSelections] = useState({
    numSlides,
    layoutIds: [
      0 === numSlides ? layouts[0].id : null,
      1 === numSlides ? layouts[0].id : null,
      2 === numSlides ? layouts[0].id : null,
    ],
  });

  let n = layoutSelections.numSlides;
  const adjustedLayoutIds = [...layoutSelections.layoutIds];

  while (n > numSlides) {
    const prevLayout = layoutIndex[adjustedLayoutIds[n]];
    n = n - 1;
    if (!adjustedLayoutIds[n])
      adjustedLayoutIds[n] = prevLayout
        ? prevLayout.downgradeTo
        : layoutsByNumSlides(n)[0].id;
  }
  while (n < numSlides) {
    const prevLayout = layoutIndex[adjustedLayoutIds[n]];
    n = n + 1;
    if (!adjustedLayoutIds[n])
      adjustedLayoutIds[n] = prevLayout
        ? prevLayout.upgradeTo
        : layoutsByNumSlides(n)[0].id;
  }
  let adjustedLayoutSelections = layoutSelections;
  if (layoutSelections.numSlides !== numSlides)
    adjustedLayoutSelections = {
      numSlides,
      layoutIds: adjustedLayoutIds,
    };

  useEffect(() => {
    if (adjustedLayoutSelections !== layoutSelections)
      setLayoutSelections(adjustedLayoutSelections);
  }, [layoutSelections, adjustedLayoutSelections]);

  const layout = layoutIndex[adjustedLayoutIds[numSlides]] || layouts[0];
  const setLayout = useCallback(
    (id) =>
      setLayoutSelections((s) => {
        if (s.numSlides === numSlides && s.layoutIds[numSlides] === id)
          return s;
        const layoutIds = [...s.layoutIds];
        layoutIds[numSlides] = id;
        return { numSlides, layoutIds };
      }),
    [numSlides]
  );

  const layoutItems =
    layouts.length === 1
      ? []
      : layouts
          .map(({ id, label, labelBefore, dividerBefore }) => {
            return [
              dividerBefore ? { divider: true } : null,
              labelBefore ? { label: labelBefore } : null,
              { value: id, label: label },
            ].filter((line) => !!line);
          })
          .flat();
  if (cameras.length > 1) {
    if (layoutItems.length > 0) layoutItems.push({ divider: true });
    layoutItems.push({ label: "Select which camera view to show:" });
    cameras.forEach((cameraName) => {
      layoutItems.push({
        isAlt: true,
        value: cameraName,
        label:
          cameraName === "editedcamera"
            ? "Videographer's camera"
            : cameraName === "camera"
            ? "Fixed in-room camera"
            : cameraName,
      });
    });
  }

  const layoutSelector =
    layoutItems.length === 0 ? null : (
      <Dropdown
        className="layout-selector"
        up
        right
        hoverable
        selected={layout.id}
        setSelected={setLayout}
        altSelected={camera}
        setAltSelected={setSelectedCamera}
        items={layoutItems}
      >
        <Button color="success" onClick={(e) => e.currentTarget.blur()}>
          <Icon icon={FaVideo} /> / <Icon icon={FaListAlt} />
        </Button>
      </Dropdown>
    );
  const [
    resolutionPreference,
    setResolutionPreference,
  ] = useResolutionSelection();

  const legacyFormat =
    !isLink &&
    !isEmulated &&
    mediaInfo.feeds[camera].resolutions.length === 1 &&
    mediaInfo.feeds[camera].resolutions[0] < 1000;

  const resolutionSelector = (
    <Dropdown
      className="resolution-selector"
      up
      right
      hoverable
      selected={resolutionPreference}
      setSelected={setResolutionPreference}
      items={
        isLink
          ? [
              { label: "Resolution switching unavailable" },
              {
                label: (
                  <p>
                    This is an externally recorded, single-resolution video, not
                    a multi-resolution video from our on-premises Fields
                    <i>Live</i> sysem
                  </p>
                ),
              },
            ]
          : legacyFormat
          ? [
              { label: "Resolution switching unavailable" },
              {
                label: (
                  <p>Higher-resolution versions are still in preparation </p>
                ),
              },
            ]
          : [
              { label: "Video Resolution" },
              { label: "Auto - match to window width", value: "auto" },
              { label: "High resolution", value: "high" },
              { label: "Low resolution", value: "low" },
            ]
      }
    >
      <IconButton color="success" icon={FaCog} />
    </Dropdown>
  );

  const feedNames = {
    camera: camera,
    slides0: slides[0],
    slides1: slides[1],
  };

  return (
    <Fragment>
      <div className="main-viewer">
        <VideoLayout
          {...{
            allFeedDetails,
            layoutSpec: layout,
            feedNames,
            syncState,
            started,
            startHandlerRef,
            setSmallIcons,
          }}
        />

        {isLink || isEmulated ? null : (
          <Player
            syncState={syncState}
            hasAudio
            isAudioOnly
            volume={syncState.volume}
            muted={syncState.muted}
            src={mediaBaseURL + path + "/audio.mp4"}
            start={mediaInfo.audioStart || 0}
          />
        )}
      </div>
      {legacyFormat ? (
        <div className="bottom-notice">
          A higher resolution version of this video is iin preparation and
          should be available in a few weeks.
        </div>
      ) : null}
      <SyncController
        state={syncState}
        settingsMenu={
          <Fragment>
            {layoutSelector}
            {resolutionSelector}
          </Fragment>
        }
        nextSlideStateChange={nextSlideStateChange}
        id={id}
        started={started}
        setStarted={setStarted}
        startHandlerRef={startHandlerRef}
        smallIcons={smallIcons}
      />
    </Fragment>
  );
}

export default LayoutManager;
