import React, { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";

function StreamPropertiesReader({ videoPath, setProperties }) {
  const dispatch = useDispatch();
  const onLoaded = useCallback(
    (e) => {
      const video = e.currentTarget;
      const info = {
        start: 0,
        end: video.duration,
        isExternal: true,
        feeds: {
          camera: {
            resolutions: [video.videoWidth],
            zoomCapabilityByResolution: {
              [video.videoWidth]: {
                zoomByScaling: true,
                height: video.videoHeight,
                displayHeight: video.videoHeight,
              },
            },
          },
        },
      };
      dispatch(setProperties(info));
    },
    [dispatch, setProperties]
  );

  const mountedRef = useRef(null);
  const onMountOrUnmount = useCallback(
    (ref) => {
      if (ref) {
        mountedRef.current = ref;
      } else {
        if (mountedRef.current) {
          mountedRef.current.src = "";
          mountedRef.current.load();
          mountedRef.current = null;
        }
      }
    },
    [mountedRef]
  );

  return (
    <video
      className="is-hidden"
      ref={onMountOrUnmount}
      onLoadedMetadata={onLoaded}
      src={videoPath}
    />
  );
}

export default StreamPropertiesReader;
