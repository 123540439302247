import { useEffect } from "react";
import { title } from "config";

function RenderTitle(props) {
  useEffect(() => {
    document.title =
      title +
      (props.title
        ? ": " + props.title
        : props.unimplemented
        ? "Under Development"
        : "");
  }, [props.title, props.unimplemented]);
  return props.children || "";
}

export default RenderTitle;
