import { createSlice } from "@reduxjs/toolkit";

export const searchTermSlice = createSlice({
  name: "searchTerm",
  initialState: "",
  reducers: {
    set: (state, action) => {
      return action.payload;
    },
  },
});

export const selectSearchTerm = (state) => state.searchTerm;
export default searchTermSlice.reducer;
