/* Our replacememt for the react-bulma-components dropdown, which is
   too resrictive */

import React, { useState } from "react";
import Icon from "util/icon";
import { FaCheck, FaCircle } from "react-icons/fa";

// We still need the CSS, even though not the components, from the
// react-bulma-components dropdown
import {} from "react-bulma-components/lib/components/dropdown";

import Columns from "react-bulma-components/lib/components/columns";

// For use both in our verson of Dropdown and in simpler Dropdown-like
// components of our own:

export function DropdownFramework({ className, trigger, children, ...flags }) {
  const [active, setActive] = useState(false);
  const [hoverable, setHoverable] = useState(true);
  flags.active = active;
  flags.hoverable = hoverable;
  const classes = Object.keys(flags)
    .filter((k) => flags[k])
    .map((k) => "is-" + k)
    .join(" ");
  return (
    <div
      className={classes + (className ? " " + className : "")}
      onClick={(e) => {
        // Click on dropdown that didn't come from trigger clears active state
        setActive(false);
      }}
    >
      <div
        onClick={(e) => {
          // Click on trigger toggles active state (and blocks the above
          // cancellation)
          setActive((a) => !a);
          e.stopPropagation();
        }}
        onTouchStart={(e) => {
          // If we observe a touch event, discontinue hover triggering
          setHoverable(false);
        }}
      >
        {trigger}
      </div>
      {children}
    </div>
  );
}

export function Dropdown({
  className,
  children: trigger,
  items,
  selected,
  setSelected,
  altSelected,
  setAltSelected,
  ...flags
}) {
  return (
    <DropdownFramework
      className={"dropdown" + (className ? " " + className : "")}
      trigger={trigger}
      {...flags}
    >
      <div className="dropdown-menu">
        <div className="dropdown-content">
          {items
            .map((item) =>
              item.isAlt
                ? {
                    ...item,
                    selected: altSelected,
                    setSelected: setAltSelected,
                  }
                : { ...item, selected, setSelected }
            )
            .map(
              (
                { value, label, divider, isAlt, selected, setSelected },
                itemNumber
              ) =>
                divider ? (
                  <div
                    key={"item-" + itemNumber}
                    className="dropdown-divider"
                  ></div>
                ) : value === undefined ? (
                  // See comments in App.scss for our custom
                  // "is-static" class name
                  <div
                    key={"item-" + itemNumber}
                    className="dropdown-item is-static"
                  >
                    {label}
                  </div>
                ) : (
                  <div
                    key={(isAlt ? "main-" : "alt-") + value}
                    className={
                      "dropdown-item" + (selected === value ? " is-active" : "")
                    }
                    onClick={(e) => {
                      setSelected(value);
                      e.currentTarget.blur();
                    }}
                  >
                    <Columns multiline={false} breakpoint="mobile">
                      <Columns.Column narrow>
                        <Icon
                          icon={isAlt ? FaCircle : FaCheck}
                          className={selected === value ? "" : "is-invisible"}
                        />
                      </Columns.Column>
                      <Columns.Column> {label}</Columns.Column>
                    </Columns>
                  </div>
                )
            )}
        </div>
      </div>
    </DropdownFramework>
  );
}

export default Dropdown;
