import { Redirect, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { homepage } from "config";
import { savePath, selectRequestedPath } from "redux/features/routes";

export function TemporaryRedirect(props) {
  const location = useLocation();
  let path = location.pathname;
  if (path.indexOf(homepage) === 0) path = path.replace(homepage, "");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(savePath(path));
    return;
  });
  return <Redirect to={props.to} />;
}

export function UndoTemporaryRedirect(props) {
  const path = useSelector(selectRequestedPath) || "/";
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(savePath(null));
    return;
  });
  return <Redirect to={path} />;
}
