import React, { Fragment, useEffect } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { legacySlice } from "redux/features/legacy";
import TopBar from "components/topbar";
import Loading from "util/loading";

function LegacyPage({ what }) {
  const location = useLocation();
  const path = location.pathname;

  const lookup = what === "talk" ? "/talk" + path : path;
  const dispatch = useDispatch();

  const lookupState = useSelector(legacySlice.selector)[lookup] || {
    uninitialized: true,
  };
  useEffect(() => {
    if (lookupState.uninitialized)
      dispatch(legacySlice.asyncActions.load(lookup));
  });

  const result = lookupState.result;
  if (result)
    return (
      <Redirect
        to={
          what === "event"
            ? "/list/event/" + result.event_id
            : "/view/" + result.talk_id
        }
      />
    );

  if (lookupState.failed) return <Redirect to="/"></Redirect>;

  return (
    <Fragment>
      <TopBar metadataState={{ metadata: { loading: true } }} />
      <div className="home-body">
        <Loading />
      </div>
    </Fragment>
  );
}

export default LegacyPage;
