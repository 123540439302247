import React from "react";
import Message from "react-bulma-components/lib/components/message";
export function ErrorMessage(props) {
  return (
    <Message color="danger">
      {props.header ? <Message.Header>{props.header}</Message.Header> : ""}
      <Message.Body>{props.children}</Message.Body>
    </Message>
  );
}

export default ErrorMessage;
