import React, { Fragment, useEffect, useCallback, useState } from "react";
import Container from "react-bulma-components/lib/components/container";
import { useParams } from "react-router-dom";
import TopBar from "components/topbar";
import { metadataSlice } from "redux/features/metadata";
import { streamPropertiesSlice } from "redux/features/streamProperties";
import StreamPropertiesReader from "components/streamPropertiesReader";

import { useSelector, useDispatch } from "react-redux";
import Box from "react-bulma-components/lib/components/box";
import {
  Field,
  Label,
  Control,
  Input,
} from "react-bulma-components/lib/components/form";
import Button from "react-bulma-components/lib/components/button";

import LayoutManager from "components/layoutManager";
function MessageBox(props) {
  return (
    <Container className="videoMessage">
      <Box>{props.children}</Box>
    </Container>
  );
}

function ViewingPage(props) {
  // Eventually implement staff editing functions if authenticated:
  // const { authenticated } = props;

  const { id } = useParams();
  const metadataState = useSelector(metadataSlice.selector)[id] || {
    loading: true,
    uninitialized: true,
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (metadataState.uninitialized)
      dispatch(metadataSlice.asyncActions.load(id));
  }, [dispatch, id, metadataState.uninitialized]);

  const metadata = metadataState.metadata || {};
  let videoPath = metadata.video_path;
  const isFieldsLive = metadata.video_type === "fieldslive";
  const isPrivate = metadata.is_private;
  const isUnreleased = metadata.is_unreleased;
  const isLink = metadata.video_type === "link";
  const isEmulated = metadata.is_emulated;
  const isYouTube = metadata.video_type === "youtube";

  const [accessCode, setAccessCode] = useState("");
  const [accessCodeInput, setAccessCodeInput] = useState(accessCode);
  const needAccessCode = isFieldsLive && isPrivate;
  if (isFieldsLive)
    videoPath =
      videoPath +
      (isPrivate ? "/private-media/" + accessCode : "/public-media");

  const missingAccessCode = needAccessCode && accessCode === "";

  const streamPropertiesState =
    useSelector(streamPropertiesSlice.selector)[id] ||
    (missingAccessCode
      ? { needAccessCode: true }
      : {
          loading: true,
        });

  const needFieldsLivePropertyLoad =
    isFieldsLive &&
    !missingAccessCode &&
    streamPropertiesState.videoPath !== videoPath;
  const wrongAccessCode =
    needAccessCode &&
    streamPropertiesState.failed &&
    streamPropertiesState.videoPath === videoPath;

  useEffect(() => {
    if (needFieldsLivePropertyLoad)
      dispatch(
        streamPropertiesSlice.asyncActions.loadFieldsLiveProperties(
          id,
          videoPath,
          isEmulated
        )
      );
  });

  const streamProperties = streamPropertiesState.data;
  const setStreamProperties = useCallback(
    (data) => streamPropertiesSlice.actions.set({ id, data }),
    [id]
  );
  const extraMetadata = {};
  if (streamProperties && streamProperties.htmlAdditionToTitle) {
    let addKey = 1;
    extraMetadata.titleAdditions = [" "];
    const plain = streamProperties.htmlAdditionToTitle.replace(
      /(.*?)<(b|em)>(.*?)<\/\2>/gi,
      (match, pretagged, tag, tagged) => {
        extraMetadata.titleAdditions.push(pretagged);
        extraMetadata.titleAdditions.push(
          tag === "b" ? (
            <b key={addKey}>{tagged}</b>
          ) : (
            <em key={addKey}>{tagged}</em>
          )
        );
        addKey = addKey + 1;
        return "";
      }
    );
    extraMetadata.titleAdditions.push(plain);
  }

  return (
    <Fragment>
      <TopBar viewing {...{ metadataState }} extra={extraMetadata} />
      {metadataState.loading ? null : isYouTube ? (
        <Fragment>
          <div className="youtube-iframe-wrapper">
            <iframe
              title="YouTube Video"
              src={`https://www.youtube.com/embed/${videoPath}`}
              allow={
                "accelerometer; autoplay; encrypted-media; gyroscope; " +
                "picture-in-picture; fullscreen"
              }
              frameborder="0"
              allowfullscreen
            />
          </div>
          <div className="youtube-footer">
            This is a YouTube hosted video; regular Fields<i>Live</i> controls
            are not available.
          </div>
        </Fragment>
      ) : videoPath ? (
        isUnreleased ? (
          <MessageBox>This video has not been publicly released</MessageBox>
        ) : streamPropertiesState.loading ? (
          <MessageBox>
            Loading video information; please wait.
            {isLink ? (
              <StreamPropertiesReader
                videoPath={videoPath}
                setProperties={setStreamProperties}
              />
            ) : null}
          </MessageBox>
        ) : missingAccessCode || wrongAccessCode ? (
          <MessageBox>
            {wrongAccessCode ? (
              <p className="access-code-error">
                That access code does not appear to be correct.
              </p>
            ) : null}
            <p className="access-code-prompt">
              This video is private; an access code is required.
            </p>
            <Field>
              <Label>Enter access code:</Label>
              <Control>
                <Input
                  type="text"
                  value={accessCodeInput}
                  onChange={(e) => setAccessCodeInput(e.target.value)}
                />
              </Control>
            </Field>
            <Field>
              <Button onClick={(e) => setAccessCode(accessCodeInput)}>
                Use This Access Code
              </Button>
            </Field>
          </MessageBox>
        ) : streamPropertiesState.failed ? (
          <MessageBox>
            An error occured loading the video:{" "}
            {streamPropertiesState.failed.message}
          </MessageBox>
        ) : !streamProperties ? (
          <MessageBox>
            An error occured loading the video: Internal error in media
            description file
          </MessageBox>
        ) : (
          <LayoutManager
            {...{
              path: videoPath,
              isLink,
              isEmulated,
              id,
              mediaInfo: streamProperties,
            }}
          />
        )
      ) : (
        <MessageBox>
          There is no video currently available for that talk.
        </MessageBox>
      )}
    </Fragment>
  );
}
export default ViewingPage;
