import layoutLabels, {
  CameraIcon,
  SlidesIcon,
  NoCameraIcon,
  NoSlidesIcon,
} from "components/layoutLabels";

const { buildLabel, half, small, large } = layoutLabels;
const CAMERA = "camera";
const SLIDES = "slides0";
const LEFTSLIDES = "slides0";
const RIGHTSLIDES = "slides1";

const baseLayout0 = {
  numSlides: 0,
  left: [],
  centre: [1, CAMERA],
  right: [],
};

const baseLayout1 = {
  ...baseLayout0,
  numSlides: 1,
  downgradeTo: "0-camera-only",
  right: [1, SLIDES],
};

const baseLayout2 = {
  ...baseLayout0,
  numSlides: 2,
  left: [1, LEFTSLIDES],
  right: [1, RIGHTSLIDES],
};

const allLayouts = [
  {
    ...baseLayout0,
    id: "0-camera-only",
    label: "camera only",
    upgradeTo: "1-equal",
  },

  {
    ...baseLayout1,
    id: "1-equal",
    labelBefore: "Show both camera and slides ...",
    label: buildLabel("equal sizes", half(CameraIcon), half(SlidesIcon)),
    upgradeTo: "2-equal",
  },

  {
    ...baseLayout1,
    id: "1-camera-larger",
    label: buildLabel("camera larger", large(CameraIcon), small(SlidesIcon)),
    upgradeTo: "2-camera-larger",
    centre: [2, CAMERA],
  },

  {
    ...baseLayout1,
    id: "1-slides-larger",
    label: buildLabel("slides larger", small(CameraIcon), large(SlidesIcon)),
    upgradeTo: "2-right-larger",
    right: [2, SLIDES],
  },

  {
    ...baseLayout1,
    id: "1-camera-only",
    label: buildLabel("camera", half(CameraIcon), half(NoSlidesIcon)),
    dividerBefore: true,
    labelBefore: "Show only...",
    upgradeTo: "2-camera-only",
    right: [],
  },

  {
    ...baseLayout1,
    id: "1-slides-only",
    label: buildLabel("slides", half(NoCameraIcon), half(SlidesIcon)),
    upgradeTo: "2-right-only",
    centre: [],
  },

  {
    ...baseLayout2,
    id: "2-equal",
    labelBefore: "Show camera and both slides ...",
    label: buildLabel(
      "equal sizes",
      small(SlidesIcon),
      small(CameraIcon),
      small(SlidesIcon)
    ),
    downgradeTo: "1-equal",
  },

  {
    ...baseLayout2,
    id: "2-camera-larger",
    label: buildLabel(
      "camera larger",
      large(CameraIcon),
      small(SlidesIcon, SlidesIcon)
    ),
    downgradeTo: "1-camera-larger",
    left: [],
    centre: [2, CAMERA],
    right: [1, LEFTSLIDES, RIGHTSLIDES],
  },

  {
    ...baseLayout2,
    id: "2-right-larger",
    label: buildLabel(
      "right/current slides larger",
      small(CameraIcon, SlidesIcon),
      large(SlidesIcon)
    ),
    downgradeTo: "1-slides-larger",
    left: [],
    centre: [1, CAMERA, LEFTSLIDES],
    right: [2, RIGHTSLIDES],
  },

  {
    ...baseLayout2,
    id: "2-left-larger",
    label: buildLabel(
      "left/previous slides larger",
      large(SlidesIcon),
      small(CameraIcon, SlidesIcon)
    ),
    downgradeTo: "1-slides-larger",
    left: [2, LEFTSLIDES],
    centre: [1, CAMERA, RIGHTSLIDES],
    right: [],
  },

  {
    ...baseLayout2,
    id: "2-camera-only",
    label: buildLabel(
      "camera",
      small(NoSlidesIcon),
      small(CameraIcon),
      small(NoSlidesIcon)
    ),
    dividerBefore: true,
    labelBefore: "Show only...",
    downgradeTo: "1-camera-only",
    left: [],
    right: [],
  },

  {
    ...baseLayout2,
    id: "2-slides-only",
    label: buildLabel(
      "slides",
      small(SlidesIcon),
      small(NoCameraIcon),
      small(SlidesIcon)
    ),
    downgradeTo: "1-slides-only",
    centre: [],
  },

  {
    ...baseLayout2,
    id: "2-right-only",
    label: buildLabel(
      "right/current slides",
      small(NoSlidesIcon),
      small(NoCameraIcon),
      small(SlidesIcon)
    ),
    downgradeTo: "1-slides-only",
    left: [],
    centre: [],
  },

  {
    ...baseLayout2,
    id: "2-left-only",
    label: buildLabel(
      "left/previous slides",
      small(SlidesIcon),
      small(NoCameraIcon),
      small(NoSlidesIcon)
    ),
    downgradeTo: "1-slides-only",
    centre: [],
    right: [],
  },
];

export default allLayouts;
