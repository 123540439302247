import { numericAscending } from "util/sort";
import { useResolutionSelection } from "redux/features/resolutions";
import { useCallback } from "react";

export function useClosestResolutionFinder() {
  const [resolutionPreference] = useResolutionSelection();
  return useCallback(
    (feedDetails, forWidth) => {
      const n = feedDetails.resolutions.length;
      const manualChoice =
        resolutionPreference === "low"
          ? [...feedDetails.resolutions].sort(numericAscending)[0]
          : resolutionPreference === "high"
          ? [...feedDetails.resolutions].sort(numericAscending)[n - 1]
          : null;

      if (manualChoice) return manualChoice;

      // On a device with high pixel ratio, don't multiply the css pixel width
      // by the full ratio (which would be a waste trying for a high-def
      // feed on a small device) but also don't keep the original width
      // since user might zoom in; use a ratio half-way in between as a
      // compromise.
      const targetResolution =
        window.devicePixelRatio && window.devicePixelRatio > 1
          ? forWidth * (1 + (window.devicePixelRatio - 1) / 2)
          : forWidth;

      // Prefer higher resolutions over lower ones, so weight the distance
      // differently. Specifically: on an HD screen (1920 wide) displaying
      // camera  / slides side by side, space allocated to camera will be
      // 960. We want the HD feed (distance 960 away) to be considered
      // closer than the low-res 504-wide feed (distance 456 away) so multiply
      // lower-res feed distances by something larger than 960/456 = 2.1. We
      // choose 2.2.
      const distance = (res) =>
        res > targetResolution
          ? res - targetResolution
          : 2.2 * (targetResolution - res);

      return [...feedDetails.resolutions].sort(
        (a, b) => distance(a) - distance(b)
      )[0];
    },
    [resolutionPreference]
  );
}

export function getDimensionsForResolution(feedDetails, resolution) {
  const {
    maxZoomLevel, // Not present when zoomByScaling is true
    zoomByScaling,
    height: originalSourceHeight, // Only present when zoomByScaling is true
    displayHeight,
    heightByLevel, // Might not be present when zoomByScaling is true
    maxXPositionByLevel, // Not present when zoomByScaling is true
    maxYPositionByLevel, // Not present when zoomByScaling is true
    cropRatioTop, // Only present when zoomByScaling is true
  } = feedDetails.zoomCapabilityByResolution[resolution];

  return {
    maxZoomLevel: zoomByScaling ? 1 : maxZoomLevel,
    zoomByScaling,
    originalSourceHeight,
    displayHeight,
    heightByLevel: heightByLevel || [displayHeight, displayHeight],
    maxXPositionByLevel: zoomByScaling ? [0, 2] : maxXPositionByLevel,
    maxYPositionByLevel: zoomByScaling ? [0, 2] : maxYPositionByLevel,
    cropRatioTop,
  };
}
