/*
 Metadata to communicate to the viewer (lecture title, speaker, etc.),
 as distinct from technical metadata about video resolution, duration, etc.
*/

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiPayloadCreator } from "util/api";

const apiCall = createAsyncThunk(
  "metadata/apiCall",
  apiPayloadCreator(["metadata", "videos/info"], "get")
);

export const metadataSlice = createSlice({
  name: "metadata",
  initialState: {},
  reducers: {},
  extraReducers: {
    [apiCall.pending]: (state, action) => {
      const {
        arg: { id },
      } = action.meta;
      if (!state[id]) state[id] = { loading: true };
    },
    [apiCall.fulfilled]: (state, action) => {
      const {
        arg: { id },
      } = action.meta;
      state[id].loading = false;
      state[id].metadata = action.payload;
    },
    [apiCall.rejected]: (state, action) => {
      const {
        arg: { id },
      } = action.meta;
      state[id].loading = false;
      state[id].failed = action.payload;
    },
  },
});

metadataSlice.selector = (store) => store.metadata;

metadataSlice.asyncActions = {
  load: (id) => (dispatch, getState) => {
    const current = metadataSlice.selector(getState())[id];
    if (!current || !current.loading)
      dispatch(
        apiCall({
          id: id,
          path: "/" + id,
        })
      );
  },
};

export default metadataSlice.reducer;
