import React, { useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectAuth, authStatus, setLoggedOut } from "redux/features/auth";
import { NavbarLink } from "util/navbarLink";

import Navbar from "react-bulma-components/lib/components/navbar";
import Button from "react-bulma-components/lib/components/button";
import Element from "react-bulma-components/lib/components/element";
import Content from "react-bulma-components/lib/components/content";
import Heading from "react-bulma-components/lib/components/heading";
import Math from "util/mathjax";
import dayjs from "dayjs";

import logo from "media/fields-logo.png";
import Icon from "util/icon";
import { FaSearch, FaCalendarAlt } from "react-icons/fa";

import WithTooltip from "util/tooltip";

function DisplayVideoMetadata({ metadataState = { loading: true }, extra }) {
  const { metadata = {} } = metadataState;

  const datetime =
    metadata.date && metadata.time
      ? dayjs(metadata.date + "T" + metadata.time + ":00").format(
          "h:mma dddd MMMM D, YYYY"
        )
      : "";

  return (
    <Navbar.Item className="is-spaced" renderAs="div">
      {metadataState.loading ? (
        <Fragment>
          <Button loading color="success" /> Fetching video information
        </Fragment>
      ) : metadataState.failed ? (
        <Content className="metadata-load-failure">
          <div className="failure-header">
            Failed to load video information:
          </div>
          <div className="failure-body">{metadataState.failed.message}</div>
        </Content>
      ) : (
        <Content className="metadata-box">
          <div className="event">
            <Math>{metadata.event_title}</Math>
          </div>
          <div className="date-time-speaker">
            <span className="date-time">
              {datetime} {metadata.speaker_text ? ": " : null}
            </span>
            {metadata.speaker_text ? (
              <span className="speaker">{metadata.speaker_text}</span>
            ) : null}
          </div>
          <div className="talk-title">
            <Math>
              {metadata.title}
              {extra ? (
                <span className="additions">
                  {extra.titleAdditions || null}{" "}
                </span>
              ) : null}
            </Math>
          </div>
        </Content>
      )}
    </Navbar.Item>
  );
}

function DisplayListHeading({ metadataState = {} }) {
  const { metadata = {} } = metadataState;
  return (
    <Navbar.Item className="is-spaced" renderAs="div">
      {metadataState.loading ? (
        <Fragment>
          <Button loading color="success" /> Fetching video list
        </Fragment>
      ) : metadataState.failed ? (
        <Content className="metadata-load-failure">
          <div className="failure-header">Failed to load video list:</div>
          <div className="failure-body">{metadataState.failed.message}</div>
        </Content>
      ) : (
        <Content className="metadata-box">
          {metadata.heading ? (
            <Heading size={3}>
              {metadata.headingMath ? (
                <Math>{metadata.heading} </Math>
              ) : (
                metadata.heading
              )}
            </Heading>
          ) : null}
          {metadata.subheading ? (
            <Heading size={5} subtitle>
              {metadata.subheading}
            </Heading>
          ) : null}
        </Content>
      )}
    </Navbar.Item>
  );
}

export function TopBar(props) {
  const [burgerOpen, setBurgerOpen] = useState(false);
  const dispatch = useDispatch();

  const handleBurgerToggle = () => setBurgerOpen(!burgerOpen);
  const handleLogout = () => dispatch(setLoggedOut());

  const auth = useSelector(selectAuth);
  const loggedIn =
    auth.status === authStatus.authenticatedStaff && !props.thinking;
  const loggedOut = auth.status === authStatus.loggedOut && !props.thinking;
  const authenticating = auth.status === authStatus.authenticatingStaff;

  const { viewing, metadataState, extra } = props;

  const leftMenuItems = viewing ? (
    <DisplayVideoMetadata {...{ metadataState, extra }} />
  ) : (
    <DisplayListHeading {...{ metadataState }} />
  );
  const rightMenuItems = (
    <Fragment>
      <WithTooltip
        component={NavbarLink}
        to="/browse"
        tip="Browse by date"
        tipPosition="bottom"
      >
        <Icon icon={FaCalendarAlt} size="large" />
      </WithTooltip>
      <Navbar.Item renderAs="div" className="right-bar-spacer" />
      <WithTooltip
        component={NavbarLink}
        to="/search"
        tip="Search for a video"
        tipPosition="bottom"
      >
        <Icon icon={FaSearch} size="large" />
      </WithTooltip>
      <Navbar.Item renderAs="div" className="right-bar-spacer" />
    </Fragment>
  );
  const authItems = loggedIn ? (
    <>
      <Navbar.Item renderAs="div" italic>
        <span className="is-italic">
          Logged in as <b>{auth.details.staffUsername}</b>
        </span>
      </Navbar.Item>
      <Navbar.Item renderAs="div">
        <Button onClick={handleLogout}>Log Out</Button>
      </Navbar.Item>
    </>
  ) : loggedOut ? null : (
    <>
      <Navbar.Item renderAs="div" italic>
        <span className="is-italic">
          {authenticating ? "Authenticating ..." : "Initializing ..."}
        </span>
      </Navbar.Item>
      <Navbar.Item renderAs="div">
        <Button loading color="success">
          Log Out
        </Button>
      </Navbar.Item>
    </>
  );
  const fixed = props.fixed ? "top" : null;
  return (
    <Navbar color="success" className="ours" active={burgerOpen} fixed={fixed}>
      <Navbar.Brand>
        <WithTooltip
          component={Navbar.Item}
          tip={`Fields
Institute
home page
`}
          tipPosition="bottom"
          tipMultiline="centered"
          renderAs="a"
          href="https://www.fields.utoronto.ca"
        >
          <img src={logo} alt="FIELDS" />
        </WithTooltip>
        <NavbarLink to="/">
          <Element
            renderAs="div"
            textSize={4}
            textWeight="bold"
            textAlignment="centered"
          >
            Fields<i>Live</i>
            <br />
            Video Archive
          </Element>
        </NavbarLink>

        <Navbar.Burger onClick={handleBurgerToggle} />
      </Navbar.Brand>

      <Navbar.Menu className="is-success">
        <Navbar.Container>
          <Navbar.Item renderAs="div" />
          {leftMenuItems}
        </Navbar.Container>
        <Navbar.Container position="end">
          {rightMenuItems}
          {authItems}
        </Navbar.Container>
      </Navbar.Menu>
    </Navbar>
  );
}

export default TopBar;
