/* Uncomment when required for status notices...

import React from "react";
import Message from "react-bulma-components/lib/components/message";
import Icon from "util/icon";
import { IoMdConstruct } from "react-icons/io";

*/

function StatusNotice() {
  return (
    /* To add a status notice, uncomment and fill in below:

    <Message color="warning">
      <Message.Header>
        <p>
          <Icon icon={IoMdConstruct} size="large" />
               HEADER (E.g. Under Construction)
        </p>
      </Message.Header>
      <Message.Body>
      BODY
      </Message.Body>
    </Message>

    */

    null
  );
}

export default StatusNotice;
