import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Redirect } from "react-router-dom";

import { browseSlice } from "redux/features/browse";
import { selectBrowseMonth } from "redux/features/browseMonth";

import BrowseMonthInput, { browsePath } from "components/browseMonthInput";

import Container from "react-bulma-components/lib/components/container";
import Box from "react-bulma-components/lib/components/box";
import TopBar from "components/topbar";
import Loading from "util/loading";
import TalkList from "components/talkList";
import StatusNotice from "components/statusNotice";

function BrowsePage(props) {
  const { year, month } = useParams();
  const browseMonth = useSelector(selectBrowseMonth);

  const pagePath = year && month ? `/browse/${year}/${month}` : null;
  const urlPath = year && month ? `month/${year}/${month}` : null;

  const browseState = useSelector(browseSlice.selector);
  const browse = urlPath
    ? browseState[urlPath] || {
        uninitialized: true,
        loading: true,
      }
    : null;

  const dispatch = useDispatch();
  useEffect(() => {
    if (browse && browse.uninitialized)
      dispatch(browseSlice.asyncActions.load(urlPath));
  });

  const data = browse ? browse.results : null;

  const selectedPagePath = browseMonth.uninitialized
    ? null
    : browsePath(browseMonth);

  const talks = data ? data.talks : [];

  if (selectedPagePath && !pagePath) return <Redirect to={selectedPagePath} />;
  return (
    <Fragment>
      <TopBar metadataState={{ metadata: { heading: "Browse by Month" } }} />
      <div className="browse-body">
        <StatusNotice />
        <BrowseMonthInput
          autoUpdate
          initialMonth={pagePath ? { year, month } : null}
        />

        <Container className="browse-result-heading-container">
          {urlPath && browse.loading ? (
            <div>
              Looading video list <Loading />
            </div>
          ) : null}
        </Container>

        {data ? (
          <Box className="browse-result-container">
            <TalkList talks={talks} singleYear />
          </Box>
        ) : null}
      </div>
    </Fragment>
  );
}

export default BrowsePage;
