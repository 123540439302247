import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiPayloadCreator } from "util/api";

const apiCall = createAsyncThunk(
  "reportViewing/apiCall",
  apiPayloadCreator(["metadata", "videos/report-viewing/"], "post")
);

export const reportViewingSlice = createSlice({
  name: "reportViewing",
  initialState: {},
  reducers: {
    setViewed: (state, action) => {
      const { talkId, sessionStart, position } = action.payload;
      if (!state.viewerId) {
        try {
          state.viewerId = window.localStorage.getItem("fieldsLiveViewerId");
        } catch (error) {
          state.viewerId = null;
        }
      }
      const key = `${talkId} ${sessionStart}`;
      if (!state[key])
        state[key] = {
          viewed: new Set([]),
          recorded: new Set([]),
          reporting: false,
        };
      state[key].viewed.add(position);
    },
  },

  extraReducers: {
    [apiCall.pending]: (state, action) => {
      const {
        arg: { talkId, sessionStart },
      } = action.meta;
      const key = `${talkId} ${sessionStart}`;
      state[key].reporting = true;
    },

    [apiCall.fulfilled]: (state, action) => {
      const {
        arg: { talkId, sessionStart, positions },
      } = action.meta;
      const key = `${talkId} ${sessionStart}`;
      state[key].reporting = false;
      positions.forEach((position) => {
        state[key].recorded.add(position);
      });
      const result = action.payload;
      if (result.viewerId && result.viewerId !== state.viewerId) {
        try {
          window.localStorage.setItem("fieldsLiveViewerId", result.viewerId);
        } catch (e) {}
        state.viewerId = result.viewerId;
      }
    },

    [apiCall.rejected]: (state, action) => {
      const {
        arg: { talkId, sessionStart },
      } = action.meta;
      const key = `${talkId} ${sessionStart}`;
      state[key].reporting = false;
    },
  },
});

reportViewingSlice.selector = (store) => store.reportViewing;

reportViewingSlice.asyncActions = {
  reportUnrecorded: (talkId, sessionStart) => (dispatch, getState) => {
    const key = `${talkId} ${sessionStart}`;
    const state = reportViewingSlice.selector(getState());
    if (!state[key]) return;
    if (state[key].reporting) return;
    const positions = [];
    state[key].viewed.forEach((position) => {
      if (!state[key].recorded.has(position)) positions.push(position);
    });

    if (positions.length > 0)
      dispatch(
        apiCall({
          talkId,
          sessionStart,
          positions,
          path: talkId,
          data: {
            sessionStart,
            positionsViewed: positions,
            viewerId: state.viewerId,
          },
        })
      );
  },
};

export default reportViewingSlice.reducer;
