export const mediaBaseURL = process.env.REACT_APP_LOCAL_MEDIA
  ? "https://localhost:3200/"
  : "https://www.fields.utoronto.ca/video-archive-2.0/";

export const apiBaseURLs = {
  auth: process.env.REACT_APP_LOCAL_AUTH
    ? "https://localhost:2999/api/v1"
    : "https://portal.fields.utoronto.ca/api/v1",
  metadata: process.env.REACT_APP_LOCAL_METADATA
    ? "https://localhost:2999/api/v1"
    : "https://portal.fields.utoronto.ca/api/v1",
  media: mediaBaseURL,
};

// If other than /, this has to be the same as what is stored in package.json.
// I can't figure out how to access package.json directly, as the standard
// create-react-app configuration limits imports to the src directory.
export const homepage = "/";

export const title = "FieldsLive Video";
