import React from "react";
import Button from "react-bulma-components/lib/components/button";
import Icon from "util/icon";

function IconButton(props) {
  const { icon, size, onClick, children, rightIcon, ...buttonProps } = props;
  const buttonSize = size === "small" ? "small" : undefined;
  return (
    <Button
      size={buttonSize}
      onClick={(e) => {
        // Remove keyboard focus ring (if present) after clicking
        e.currentTarget.blur();
        if (onClick) onClick(e);
      }}
      // Don't put keyboard-focus ring around button when clicking it
      onMouseDown={(e) => e.preventDefault()}
      {...buttonProps}
    >
      {rightIcon ? null : <Icon icon={icon} size={size} />}
      {children ? <span>{children}</span> : null}
      {rightIcon ? <Icon icon={icon} size={size} /> : null}
    </Button>
  );
}

export default IconButton;
