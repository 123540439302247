import React, { useState } from "react";

function WithTooltip({
  component,
  tipPosition,
  tip,
  tipMultiline,
  className,
  wrapped,
  wrapperComponent,
  wrapperClass,
  children,
  ...props
}) {
  const Component = component || "span";
  const Outer = wrapped ? wrapperComponent || "div" : Component;
  const outerClass = wrapped ? wrapperClass || "tooltip-wrapper" : className;
  const outerProps = wrapped ? {} : props;
  const inner = wrapped ? (
    <Component className={className} {...props}>
      {children}
    </Component>
  ) : (
    children
  );

  const [active, setActive] = useState(false);
  return (
    <Outer
      {...outerProps}
      data-tooltip={tip}
      className={
        "has-tooltip-arrow" +
        (tipPosition ? " has-tooltip-" + tipPosition : "") +
        (active ? " has-tooltip-active" : "") +
        // The has-tooltip-multiline class seems not to work, but
        // specifying an alignment does, so pass
        // tipMultiline={"left"/"centered"/"right"}
        (tipMultiline ? " has-tooltip-text-" + tipMultiline : "") +
        (outerClass ? " " + outerClass : "")
      }
      onTouchStart={(e) => setActive(true)}
      onTouchEnd={(e) => setActive(false)}
      {...outerProps}
    >
      {inner}
    </Outer>
  );
}

export default WithTooltip;
