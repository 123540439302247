import React from "react";
import TopBar from "components/topbar";

import { homepage } from "config";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import StaffLogin from "components/staffLogin";
import RenderTitle from "util/renderTitle";
import CommonApp from "commonApp";

function UnauthenticatedApp(props) {
  return (
    <>
      <Router basename={homepage}>
        <Switch>
          <Route path="/staff">
            <RenderTitle title="Login" />
            <TopBar fixed="top" />
            <StaffLogin />
          </Route>
          <Route>
            <CommonApp />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default UnauthenticatedApp;
