import { apiBaseURLs } from "config.js";
import axios from "axios";

axios.defaults.headers = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
};

const axiosInstances = Object.fromEntries(
  Object.entries(apiBaseURLs).map(([name, url]) => [
    name,
    axios.create({ baseURL: url }),
  ])
);

export function apiPayloadCreator(
  instanceAndUrlPrefix,
  defaultMethod,
  onSuccess = (data, arg, thunkAPI) => data,
  onFailure = (payload, arg, thunkAPI) => thunkAPI.rejectWithValue(payload)
) {
  const [instanceName, urlPrefix] = Array.isArray(instanceAndUrlPrefix)
    ? instanceAndUrlPrefix
    : ["default", instanceAndUrlPrefix];
  const axiosInstance = axiosInstances[instanceName] || axiosInstances.default;
  return (arg, thunkAPI) => {
    const { authToken, path = "" } = arg;
    const url = arg.url || urlPrefix + path;
    const auth = authToken ? { headers: { "X-Auth-Token": authToken } } : {};
    return axiosInstance
      .request({ method: defaultMethod, ...arg, url: url, ...auth })
      .then((response) => onSuccess(response.data.data, arg, thunkAPI))
      .catch((error) => {
        const payload = {};
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          payload.message = error.response.data.error;
          payload.details = error.response.data;
        } else {
          payload.message = error.message || error.toJSON();
          payload.details = {};
        }
        return onFailure(payload, arg, thunkAPI);
      });
  };
}
