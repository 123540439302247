import { createSlice } from "@reduxjs/toolkit";

export const routesSlice = createSlice({
  name: "routes",
  initialState: {
    requestedPath: null,
  },
  reducers: {
    savePath: (state, action) => {
      state.requestedPath = action.payload;
    },
  },
});

export const { savePath } = routesSlice.actions;
export const selectRequestedPath = (state) => state.routes.requestedPath;
export default routesSlice.reducer;
