import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { searchTermSlice, selectSearchTerm } from "redux/features/searchTerm";
import { searchSlice } from "redux/features/search";
import SearchInput, { searchHeadingClass } from "components/searchInput";

import Container from "react-bulma-components/lib/components/container";
import Box from "react-bulma-components/lib/components/box";
import TopBar from "components/topbar";
import {
  Field,
  Control,
  Checkbox,
} from "react-bulma-components/lib/components/form";
import Button from "react-bulma-components/lib/components/button";

import Loading from "util/loading";
import TalkList from "components/talkList";
import StatusNotice from "components/statusNotice";

function SearchPage(props) {
  const searchTerm = useSelector(selectSearchTerm);
  const dispatch = useDispatch();
  const setSearchTerm = useCallback(
    (term) => dispatch(searchTermSlice.actions.set(term)),
    [dispatch]
  );

  const search = useSelector(searchSlice.selector);
  const [showEventMatch, setShowEventMatch] = useState(true);
  const [showSpeakerMatch, setShowSpeakerMatch] = useState(true);
  const [showTitleMatch, setShowTitleMatch] = useState(true);

  useEffect(() => {
    if (
      searchTerm !== "" &&
      !search.results[searchTerm] &&
      !search.searching &&
      !(search.failed && search.failed.query === searchTerm)
    ) {
      dispatch(searchSlice.asyncActions.search(searchTerm));
    }
  });

  const loading = searchTerm !== "" && !search.results[searchTerm];
  const resultsFor =
    searchTerm === ""
      ? null
      : search.results[searchTerm]
      ? searchTerm
      : search.lastSearch && search.results[search.lastSearch]
      ? search.lastSearch
      : null;
  const results = resultsFor === null ? null : search.results[resultsFor];
  const talks = results
    ? results.talks.filter(
        (talk) =>
          (showEventMatch && talk.match_for_event_title) ||
          (showSpeakerMatch && talk.match_for_speaker) ||
          (showTitleMatch && talk.match_for_talk_title)
      )
    : [];
  const tooMany = results
    ? (showEventMatch && results.too_many_for_event_title) ||
      (showSpeakerMatch && results.too_many_for_speaker) ||
      (showTitleMatch && results.too_many_for_talk_title)
    : null;

  const numWording = (what) => (
    <Fragment>
      `(talks:{results[`count_for_${what}`]}
      {results[`too_many_for_${what}`] ? (
        <Fragment>: too many to list</Fragment>
      ) : (
        <Fragment>
          , with video: {results[`count_with_video_for_${what}`]})
        </Fragment>
      )}
    </Fragment>
  );
  return (
    <Fragment>
      <TopBar metadataState={{ metadata: { heading: "Search" } }} />
      <div className="search-body">
        <StatusNotice />
        <SearchInput
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          autoUpdate
        />
        <Container className="search-result-heading-container">
          {loading ? (
            <div>
              Searching . . . <Loading />
            </div>
          ) : null}
          {results ? (
            <div className={searchHeadingClass}>
              Results for <i>{resultsFor}</i>
            </div>
          ) : (
            <Fragment>
              <div className={searchHeadingClass}>Results</div>
              <p>Search results will appear here</p>
            </Fragment>
          )}{" "}
        </Container>
        {results ? (
          <Box className="search-result-container">
            <Field kind="group">
              <Control>
                <Button isStatic className="non-button">
                  Show matches for
                </Button>
              </Control>
              <Control>
                <Checkbox
                  checked={showEventMatch}
                  onChange={(e) => setShowEventMatch((v) => !v)}
                >
                  {" "}
                  event title {numWording("event_title")}
                </Checkbox>
              </Control>
              <Control>
                <Checkbox
                  checked={showSpeakerMatch}
                  onChange={(e) => setShowSpeakerMatch((v) => !v)}
                >
                  {" "}
                  speaker
                  {numWording("speaker")}
                </Checkbox>
              </Control>
              <Control>
                <Checkbox
                  checked={showTitleMatch}
                  onChange={(e) => setShowTitleMatch((v) => !v)}
                >
                  {" "}
                  talk title {numWording("talk_title")}
                </Checkbox>
              </Control>
            </Field>
            {tooMany ? (
              <div className="too-many">
                Too many results match ({results.count}); please enter more
                search text.
              </div>
            ) : (
              <TalkList talks={talks} />
            )}
          </Box>
        ) : null}
      </div>
    </Fragment>
  );
}

export default SearchPage;
