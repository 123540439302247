import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import { Player } from "components/sync";
import IconButton from "util/iconButton";
import FadingPanel from "util/fadingPanel";
import {
  FaSearchPlus,
  FaSearchMinus,
  FaArrowLeft,
  FaArrowRight,
  FaArrowUp,
  FaArrowDown,
  FaCog,
} from "react-icons/fa";
import { MdMoreHoriz } from "react-icons/md";

import {
  useClosestResolutionFinder,
  getDimensionsForResolution,
} from "tools/feedDimensions";

import WithTooltip from "util/tooltip";

import { toast } from "react-toastify";

function equalPlayingState(a, b) {
  return (
    a.resolution === b.resolution &&
    a.zoomLevel === b.zoomLevel &&
    a.xPosition === b.xPosition &&
    a.yPosition === b.yPosition
  );
}

function unalteredRequestState(requestState, playingState) {
  return (
    requestState.centre === undefined &&
    requestState.changeZoomLevel === undefined &&
    equalPlayingState(playingState, requestState)
  );
}

function useDeterminePlayingState(requestState, targetResolution, feedDetails) {
  const request = { ...requestState };
  request.dimensions = getDimensionsForResolution(
    feedDetails,
    request.resolution
  );

  request.maxPositions = {
    x: request.dimensions.maxXPositionByLevel[request.zoomLevel] || 0,
    y: request.dimensions.maxYPositionByLevel[request.zoomLevel] || 0,
  };

  if (!request.centre)
    request.centre = {
      x: request.maxPositions.x
        ? request.xPosition / request.maxPositions.x
        : 0.5,
      y: request.maxPositions.y
        ? request.yPosition / request.maxPositions.y
        : 0.5,
    };
  request.newZoomLevel =
    // If increasing resolution, don't stay zoomed in.
    (targetResolution > request.resolution ? 0 : request.zoomLevel) +
    (request.changeZoomLevel || 0);

  const getClosestResolution = useClosestResolutionFinder();
  const resolution = getClosestResolution(feedDetails, targetResolution);
  const {
    maxZoomLevel,
    maxXPositionByLevel,
    maxYPositionByLevel,
  } = getDimensionsForResolution(feedDetails, resolution);

  const zoomLevel = Math.min(maxZoomLevel, Math.max(0, request.newZoomLevel));

  const maxXPosition = maxXPositionByLevel[zoomLevel];
  const maxYPosition = maxYPositionByLevel[zoomLevel];
  const x = Math.min(1, Math.max(0, request.centre.x));
  const y = Math.min(1, Math.max(0, request.centre.y));

  return {
    resolution,
    zoomLevel,
    xPosition: Math.round(x * maxXPosition),
    yPosition: Math.round(y * maxYPosition),
  };
}

const zoomAction = (setRequestState, zoomDelta) => (event) => {
  const positionDiv = event.currentTarget.parentElement;
  setRequestState((state) => {
    const request = { ...state, changeZoomLevel: zoomDelta };
    if (positionDiv) {
      const r = positionDiv.getBoundingClientRect();
      request.centre = {
        x: (event.clientX - r.x) / r.width,
        y: (event.clientY - r.y) / r.height,
      };
    }
    return request;
  });
};

const panAction = (setRequestState, deltaX, deltaY) => (event) =>
  setRequestState((state) => {
    return {
      ...state,
      xPosition: state.xPosition + deltaX,
      yPosition: state.yPosition + deltaY,
    };
  });

function AdjustedPlayer({
  mediaWidth,
  mediaHeight,
  xOffset,
  yOffset,
  ...playerProps
}) {
  const style = {};

  if (yOffset) style.marginTop = yOffset + "px";
  if (xOffset) style.marginLeft = xOffset + "px";
  return (
    <Player
      style={{
        width: mediaWidth + "px",
        maxWidth: mediaWidth + "px",
        height: mediaHeight + "px",
        maxHeight: mediaHeight + "px",
        marginLeft: xOffset + "px",
        marginTop: yOffset + "px",
      }}
      {...playerProps}
    />
  );
}

function VideoPane(props) {
  const {
    syncState,
    feedName,
    feedDetails,
    width,
    height,
    resolution,
    setCurrentZooms,
    started,
  } = props;

  const { hasAudio } = feedDetails;
  const setCurrentZoom = useCallback(
    (zoom) =>
      setCurrentZooms((zooms) =>
        zooms[feedName] === zoom ? zooms : { ...zooms, [feedName]: zoom }
      ),
    [setCurrentZooms, feedName]
  );

  const [playingState, setPlayingState] = useState({
    resolution,
    zoomLevel: 0,
    xPosition: 0,
    yPosition: 0,
    preparedFor: null,
  });

  useEffect(() => setCurrentZoom(playingState.zoomLevel));

  const [requestState, setRequestState] = useState({
    resolution,
    zoomLevel: 0,
    xPosition: 0,
    yPosition: 0,
  });

  const newState = useDeterminePlayingState(
    requestState,
    resolution,
    feedDetails
  );

  useEffect(() => {
    if (!unalteredRequestState(requestState, newState))
      setRequestState(newState);
  }, [newState, requestState]);

  const [activePlayer, setActivePlayer] = useState(0);

  const onNewStatePlaying = useCallback(() => {
    setActivePlayer((p) => 1 - p);

    // When new state is ready and becomes the playing state, set the
    // playing state to the new state's values. We don't set it to the
    // newState object itself because that is recalculated on every
    // render and we don't want the callback to have to be recreated
    // on every render.
    setPlayingState({
      resolution: newState.resolution,
      zoomLevel: newState.zoomLevel,
      xPosition: newState.xPosition,
      yPosition: newState.yPosition,
      preparedFor: newState.resolution,
    });
  }, [
    newState.resolution,
    newState.zoomLevel,
    newState.xPosition,
    newState.yPosition,
  ]);

  const start = feedDetails.start || 0;
  const trimmed = feedDetails.trimmed || {};
  const playingStart =
    start +
    (trimmed[
      `${playingState.resolution}-${playingState.zoomLevel}-` +
        `${playingState.xPosition}-${playingState.yPosition}`
    ] || 0);
  const newStart =
    start +
    (trimmed[
      `${newState.resolution}-${newState.zoomLevel}-` +
        `${newState.xPosition}-${newState.yPosition}`
    ] || 0);

  // When we prepare a new video for playback starting at a position other than
  // the beginning, we don't want to display it while the beginning frame is
  // showing -- that would be distracting (e.g., when switching views from
  // slides to camera in the middle of a video, we don't want to see a picture
  // of how the room looked at the start of the talk for a split-second before
  // seeing the current view).

  // After much experimentation with different techniques, it seems the best
  // approach (at least for Firefox and Chrome) is to listen for a timeupdate
  // event, with the added quirk that, when in playing mode (nonzero velocity
  // in the timing object), wait for a second timeupdate event with an
  // altered currentTime, since Firefox fires the first timeupdate event
  // before the frame is actually rendered.

  // When playing back from the beginning, we simply display it as soon as
  // the canplay event is fired.

  // Record the currentTime last seen in a timeupdate event
  const timeUpdateRef = useRef(null);

  const onNewTimeUpdate = useCallback(
    (event) => {
      const lastUpdateSeen = timeUpdateRef.current;
      timeUpdateRef.current = event.target.currentTime;
      if (
        syncState.timingObject.query().velocity === 0 ||
        (lastUpdateSeen && timeUpdateRef.current !== lastUpdateSeen)
      ) {
        timeUpdateRef.current = null;
        onNewStatePlaying();
      }
    },
    [syncState, onNewStatePlaying]
  );

  const onNewCanPlay = useCallback(
    (event) => {
      if (syncState.timingObject.query().position < newStart + 0.001)
        onNewStatePlaying();
    },
    [syncState, onNewStatePlaying, newStart]
  );

  const buildURL = ({
    resolution,
    zoomLevel,
    xPosition,
    yPosition,
    preparedFor,
  }) => {
    if (feedDetails.url) return feedDetails.url;
    if (preparedFor !== undefined && preparedFor !== resolution)
      return undefined;
    const { zoomByScaling } = getDimensionsForResolution(
      feedDetails,
      resolution
    );
    return (
      feedDetails.baseURL +
      "-" +
      resolution +
      (zoomByScaling ? "-0-0-0" : `-${zoomLevel}-${xPosition}-${yPosition}`) +
      feedDetails.suffix
    );
  };

  const activeURL = buildURL(playingState);
  const desiredURL = buildURL(newState);
  const newURL = activeURL === desiredURL ? null : desiredURL;

  // If URL has not changed but playing state has (to zoom by scaling,
  // for example), load new playing state.
  const playingStateChanged = !equalPlayingState(newState, playingState);
  useEffect(() => {
    if (!newURL && playingStateChanged) setPlayingState(newState);
  }, [newURL, playingStateChanged, newState]);

  const loadingWhat = feedName.includes("camera") ? "camera" : "slides";

  const onNewFeedTimeout = useCallback(() => {
    if (!newURL) return;
    if (!activeURL) {
      toast.error(
        <Fragment>
          Unable to load {loadingWhat} feed; try reloading the page in a few
          minutes
          {feedDetails.resolutions.length > 1 ? (
            <Fragment>
              , setting a lower resolution in the menu <FaCog /> below,
            </Fragment>
          ) : null}{" "}
          or switching to a different browser.
        </Fragment>
      );
    } else if (newState.resolution !== playingState.resolution) {
      toast.warning(
        <Fragment>
          An error occurred trying to switch resolutions for the {loadingWhat}{" "}
          feed; staying at current resolution.
          {feedDetails.resolutions.length > 1 ? (
            <Fragment>
              You might need to set a specific resolution in the menu <FaCog />{" "}
              below.
            </Fragment>
          ) : null}
        </Fragment>
      );
    } else if (newState.zoomLevel !== playingState.zoomLevel) {
      const inOrOut =
        newState.zoomLevel > playingState.zoomLevel ? "in" : "out";
      toast.error(`An error occurred while trying to zoom ${inOrOut};
               try again in a few minutes.`);
      setRequestState(playingState);
    } else {
      const moving =
        newState.xPosition > playingState.xPosition
          ? "moving right"
          : newState.xPosition < playingState.xPosition
          ? "moving left"
          : newState.yPosition > playingState.yPosition
          ? "moving down"
          : newState.yPosition < playingState.yPosition
          ? "moving up"
          : "switching views";
      toast.error(`An error occurred while ${moving};
               try again in a few minutes.`);
      setRequestState(playingState);
    }
  }, [
    newState.xPosition,
    newState.yPosition,
    newState.zoomLevel,
    newState.resolution,
    playingState,
    activeURL,
    newURL,
    loadingWhat,
    feedDetails.resolutions.length,
  ]);

  useEffect(() => {
    const id = window.setTimeout(onNewFeedTimeout, 10000);
    return () => window.clearTimeout(id);
  }, [newURL, onNewFeedTimeout]);

  const {
    maxZoomLevel,
    maxXPositionByLevel,
    maxYPositionByLevel,
    heightByLevel,
    originalSourceHeight,
    cropRatioTop,
    zoomByScaling,
  } = getDimensionsForResolution(feedDetails, playingState.resolution);

  const { zoomLevel, xPosition, yPosition } = newState;
  const canZoom = { in: zoomLevel < maxZoomLevel, out: zoomLevel > 0 };
  const canPan = {
    left: xPosition > 0,
    right: xPosition < maxXPositionByLevel[zoomLevel],
    up: yPosition > 0,
    down: yPosition < maxYPositionByLevel[zoomLevel],
  };

  const [modifierPressed, setModifierPressed] = useState(false);
  const shiftCheck = (keyboardOrMouseEvent) =>
    setModifierPressed(!!keyboardOrMouseEvent.shiftKey);

  useEffect(() => {
    document.addEventListener("keydown", shiftCheck);
    document.addEventListener("keyup", shiftCheck);
    return () => {
      document.removeEventListener("keydown", shiftCheck);
      document.removeEventListener("keyup", shiftCheck);
    };
  }, []);

  const zoomControls = [
    ["in", FaSearchPlus, zoomAction(setRequestState, 1), canZoom.in],
    ["out", FaSearchMinus, zoomAction(setRequestState, -1), canZoom.out],
    ["left", FaArrowLeft, panAction(setRequestState, -1, 0), canPan.left],
    ["right", FaArrowRight, panAction(setRequestState, 1, 0), canPan.right],
    ["up", FaArrowUp, panAction(setRequestState, 0, -1), canPan.up],
    ["down", FaArrowDown, panAction(setRequestState, 0, 1), canPan.down],
  ];

  const clickNothing = ["none", null];

  const clickZoomIn = ["zoom-in", zoomAction(setRequestState, 1)];
  const clickZoomOut = ["zoom-out", zoomAction(setRequestState, -1)];

  const clickZoom = modifierPressed || !canZoom.in ? clickZoomOut : clickZoomIn;
  const canClickZoom = canZoom.out || (!modifierPressed && canZoom.in);

  const clickPanLeft = ["pan-left", panAction(setRequestState, -1, 0)];
  const clickPanRight = ["pan-right", panAction(setRequestState, 1, 0)];
  const clickPanUp = ["pan-up", panAction(setRequestState, 0, -1)];
  const clickPanDown = ["pan-down", panAction(setRequestState, 0, 1)];

  const clickWait = ["wait", null];

  const tryAction = (action, condition) =>
    newURL ? clickWait : condition ? action : null;

  const tryZoom = canClickZoom ? clickZoom : clickNothing;

  const zoomOverlays = [
    ["centre", tryAction(clickZoom, canClickZoom) || clickNothing],
    ["left", tryAction(clickPanLeft, canPan.left) || tryZoom],
    ["right", tryAction(clickPanRight, canPan.right) || tryZoom],
    ["up", tryAction(clickPanUp, canPan.up) || tryZoom],
    ["down", tryAction(clickPanDown, canPan.down) || tryZoom],
  ];

  // Dimensions of media currently playing
  const {
    zoomLevel: activeZoomLevel,
    xPosition: activeXPosition,
    yPosition: activeYPosition,
  } = playingState;

  const scale = zoomByScaling ? 1 + activeZoomLevel : 1;

  const sourceWidth = playingState.resolution;
  const sourceHeight = zoomByScaling
    ? originalSourceHeight
    : heightByLevel[activeZoomLevel];
  const sourceVisibleHeight = heightByLevel[activeZoomLevel];

  const mediaScale = (scale * width) / sourceWidth;

  const mediaCropTop =
    sourceVisibleHeight < sourceHeight
      ? mediaScale * cropRatioTop * (sourceHeight - sourceVisibleHeight)
      : 0;

  const mediaVPanTotal = zoomByScaling
    ? mediaScale * sourceVisibleHeight - height
    : 0;
  const mediaHPanTotal = zoomByScaling ? mediaScale * sourceWidth - width : 0;
  const mediaWidth = mediaScale * sourceWidth;
  const mediaHeight = mediaScale * sourceHeight;

  const xOffset =
    zoomByScaling && maxXPositionByLevel[activeZoomLevel] > 0
      ? (-activeXPosition * mediaHPanTotal) /
        maxXPositionByLevel[activeZoomLevel]
      : 0;

  const yOffset =
    -mediaCropTop +
    (zoomByScaling && maxYPositionByLevel[activeZoomLevel] > 0
      ? (-activeYPosition * mediaVPanTotal) /
        maxYPositionByLevel[activeZoomLevel]
      : 0);

  const fullSizePanelSpace = Math.min(height / 32, width / 160);
  const controlIconSize =
    fullSizePanelSpace < 2
      ? "small"
      : fullSizePanelSpace < 3
      ? undefined
      : fullSizePanelSpace < 4
      ? "medium"
      : "large";

  const ControlsPanel =
    maxZoomLevel === 0
      ? null
      : ({ active }) => (
          <Fragment>
            {zoomControls.map(([key, icon, action, enabled]) => {
              const what = key === "in" || key === "out" ? "zoom" : "move";
              const disabled = !!newURL || !enabled;
              return (
                <WithTooltip
                  component={IconButton}
                  key={key}
                  color="success"
                  wrapped
                  disabled={disabled}
                  icon={icon}
                  size={controlIconSize}
                  onClick={disabled || !active ? undefined : action}
                  tip={
                    !!newURL
                      ? `loading:
please wait`
                      : !enabled
                      ? `can't ${what}
further ${key}`
                      : `${what} ${key}`
                  }
                  tipPosition="bottom"
                  tipMultiline="centered"
                />
              );
            })}
            <WithTooltip
              component={IconButton}
              color="success"
              size={controlIconSize}
              icon={MdMoreHoriz}
              wrapped
              disabled
              tip="Or click in the camera view itself"
              tipPosition="bottom"
            />
          </Fragment>
        );

  // Return a fragment with both the active player and the one (if any)
  // preparing a new view. Note that, although only the active player
  // requires positioning, we wrap the inactive one in <AdjustedPlayer/>
  // as well so they are sibling components and can remain mounted when
  // their positions are swapped. Otherwise, the unmount and remount would
  // cause the video playback to be interrupted and can even lead to stalled
  // videos if the same source is removed and reattached in quick succession
  // before being fully detached from the first mount.
  return (
    <div
      style={{ width: width + "px", height: height + "px" }}
      className="video-player"
    >
      <div className="liner">
        <AdjustedPlayer
          key={activePlayer}
          syncState={syncState}
          src={activeURL}
          start={playingStart}
          {...(hasAudio
            ? {
                hasAudio: true,
                volume: syncState.volume,
                muted: syncState.muted,
              }
            : {})}
          {...{ mediaWidth, mediaHeight, xOffset, yOffset }}
        />
        {newURL ? (
          <AdjustedPlayer
            key={1 - activePlayer}
            syncState={syncState}
            src={newURL}
            start={newStart}
            onCanPlay={onNewCanPlay}
            onTimeUpdate={onNewTimeUpdate}
            className="is-hidden"
            {...(hasAudio
              ? {
                  hasAudio: true,
                  volume: syncState.volume,
                  muted: syncState.muted,
                }
              : {})}
            {...{ mediaWidth, mediaHeight, xOffset, yOffset }}
          />
        ) : null}
      </div>
      {zoomOverlays.map(([positionClass, [actionClass, action]]) => (
        <div
          className={`overlay area-${positionClass} action-${actionClass}`}
          key={positionClass}
          onClick={action}
          onMouseMove={shiftCheck}
        ></div>
      ))}

      {maxZoomLevel === 0 ? null : (
        <FadingPanel
          className="controls-overlay"
          panelComponent={ControlsPanel}
          started={started}
        />
      )}
    </div>
  );
}

export default VideoPane;
