import React from "react";
import { Auth } from "redux/features/auth";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck,
  faAngleDown,
  faCaretDown,
  faTimes,
  faHandPointRight,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";

import UnauthenticatedApp from "unauthenticatedApp";
import AuthenticatedApp from "authenticatedApp";
import Hero from "react-bulma-components/lib/components/hero";

import "./App.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MathJaxProvider } from "util/mathjax";

library.add(
  faCheck,
  faAngleDown,
  faCaretDown,
  faTimes,
  faHandPointRight,
  faArrowDown
);

function App() {
  return (
    <MathJaxProvider>
      <div className="App">
        <Auth
          allowStaff
          ifUnauthenticated={() => <UnauthenticatedApp />}
          ifAuthenticated={() => <AuthenticatedApp />}
          ifThinking={(message) => (
            <>
              <Hero hasNavbar color="success" size="fullheight"></Hero>
            </>
          )}
        ></Auth>
        <ToastContainer />
      </div>
    </MathJaxProvider>
  );
}

export default App;
