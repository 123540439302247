import React, { useEffect, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Field, Control } from "react-bulma-components/lib/components/form";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import {
  browseMonthSlice,
  selectBrowseMonth,
} from "redux/features/browseMonth";
import { browseSlice } from "redux/features/browse";
import Loading from "util/loading";

import { FaArrowLeft, FaArrowRight, FaAngleDown } from "react-icons/fa";
import IconButton from "util/iconButton";

import MonthPicker from "react-month-picker";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";

dayjs.extend(localeData);

export const browseHeadingClass = "is-size-3 has-text-weight-bold";
export const browsePath = ({ month, year }) =>
  `/browse/${year}/${month < 10 ? "0" : ""}${month}`;

export function BrowseMonthInput(props) {
  const { autoUpdate, initialMonth: initialMonthText } = props;
  const initialMonth = initialMonthText
    ? {
        month: Number(initialMonthText.month),
        year: Number(initialMonthText.year),
      }
    : null;

  const availableDatesState = useSelector(browseSlice.selector)[
    "available-dates"
  ] || {
    uninitialized: true,
    loading: true,
  };

  const availableDates = availableDatesState.results;
  const dispatch = useDispatch();

  const months = dayjs.months();
  const monthsShort = dayjs.monthsShort();

  useEffect(() => {
    if (availableDatesState.uninitialized)
      dispatch(browseSlice.asyncActions.load("available-dates"));
  });

  const availableRange = availableDates
    ? {
        min: {
          year: Number(availableDates.earliest.substring(0, 4)),
          month: Number(availableDates.earliest.substring(5, 7)),
        },
        max: {
          year: Number(availableDates.latest.substring(0, 4)),
          month: Number(availableDates.latest.substring(5, 7)),
        },
      }
    : null;

  const browseMonth = useSelector(selectBrowseMonth);
  const pickerRef = createRef(null);

  const history = useHistory();

  const setBrowseMonth = (value) => {
    if (pickerRef.current) pickerRef.current.dismiss();
    const validValue =
      value.year < availableRange.min.year
        ? availableRange.min
        : value.year === availableRange.min.year &&
          value.month < availableRange.min.month
        ? availableRange.min
        : value.year > availableRange.max.year
        ? availableRange.max
        : value.year === availableRange.max.year &&
          value.month > availableRange.max.month
        ? availableRange.max
        : value;
    dispatch(browseMonthSlice.actions.set(validValue));
    if (autoUpdate) history.push(browsePath(validValue));
  };

  useEffect(() => {
    if (availableRange && (browseMonth.uninitialized || initialMonth))
      dispatch(
        browseMonthSlice.actions.set(initialMonth || availableRange.max)
      );
  });

  return (
    <Container className="browse-month-container">
      <Columns>
        <Columns.Column narrow className={browseHeadingClass}>
          Browse by Month
        </Columns.Column>
        <Columns.Column>
          {availableDatesState.failed ? (
            <div className="date-load-error">
              Error determining available months:{" "}
              {availableDatesState.failed.message}
            </div>
          ) : !availableDates || browseMonth.uninitialized ? (
            <div>
              <Loading />
              Checking video availability ...
            </div>
          ) : (
            <Field kind="group">
              <Control>
                <IconButton
                  size="large"
                  icon={FaArrowLeft}
                  disabled={
                    browseMonth.year === availableRange.min.year &&
                    browseMonth.month === availableRange.min.month
                  }
                  onClick={() =>
                    setBrowseMonth(
                      browseMonth.month === 1
                        ? {
                            year: browseMonth.year - 1,
                            month: 12,
                          }
                        : {
                            year: browseMonth.year,
                            month: browseMonth.month - 1,
                          }
                    )
                  }
                />
              </Control>
              <Control className="month-picker">
                <IconButton
                  icon={FaAngleDown}
                  rightIcon
                  size="medium"
                  onClick={() => {
                    if (pickerRef) pickerRef.current.show();
                  }}
                  className="is-size-5 is-fullwidth"
                >
                  {months[browseMonth.month - 1]} {browseMonth.year}
                </IconButton>
                <MonthPicker
                  ref={pickerRef}
                  years={availableRange}
                  value={browseMonth}
                  lang={monthsShort}
                  onChange={(year, month) => {
                    console.log({ year, month });
                    setBrowseMonth({ year, month });
                  }}
                />
              </Control>
              <Control>
                <IconButton
                  size="large"
                  icon={FaArrowRight}
                  disabled={
                    browseMonth.year === availableRange.max.year &&
                    browseMonth.month === availableRange.max.month
                  }
                  onClick={() =>
                    setBrowseMonth(
                      browseMonth.month === 12
                        ? {
                            year: browseMonth.year + 1,
                            month: 1,
                          }
                        : {
                            year: browseMonth.year,
                            month: browseMonth.month + 1,
                          }
                    )
                  }
                />
              </Control>
            </Field>
          )}
        </Columns.Column>
      </Columns>
    </Container>
  );
}

export default BrowseMonthInput;
