import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "react-bulma-components/lib/components/button";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { searchTermSlice } from "redux/features/searchTerm";
import { selectBrowseMonth } from "redux/features/browseMonth";
import TopBar from "components/topbar";
import SearchInput from "components/searchInput";
import BrowseMonthInput, { browsePath } from "components/browseMonthInput";
import Container from "react-bulma-components/lib/components/container";
import StatusNotice from "components/statusNotice";
import WithTooltip from "util/tooltip";

function HomePage(props) {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  useEffect(() => dispatch(searchTermSlice.actions.set("")), [dispatch]);

  const searchReady = searchTerm !== "";
  const launchSearch = (event) => {
    dispatch(searchTermSlice.actions.set(searchTerm));
    if (searchReady) history.push("/search");
  };

  const browseMonth = useSelector(selectBrowseMonth);
  const browseReady = browseMonth && !browseMonth.uninitialized;
  const launchBrowse = (event) => {
    if (browseReady) history.push(browsePath(browseMonth));
  };

  const location = useLocation();
  if (location.pathname !== "/") return <Redirect to="/" />;

  return (
    <Fragment>
      <TopBar />
      <div className="home-body">
        <StatusNotice />
        <BrowseMonthInput />
        <Container className="browse-result-heading-container">
          <WithTooltip
            wrapped
            component={Button}
            rounded
            disabled={!browseReady}
            tip={"Go to list of videos for the selected month"}
            tipPosition="right"
            onClick={launchBrowse}
          >
            Show the Month's Videos
          </WithTooltip>
        </Container>
        <Container className="home-divider">Or</Container>
        <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <Container className="search-result-heading-container">
          <WithTooltip
            wrapped
            component={Button}
            rounded
            disabled={!searchReady}
            tip={
              searchReady
                ? "Go to search results page"
                : "Please enter some search text"
            }
            tipPosition="right"
            onClick={launchSearch}
          >
            Show Search Results
          </WithTooltip>
        </Container>
      </div>
    </Fragment>
  );
}

export default HomePage;
